(function () {
  'use strict';
  angular
  .module('app')
  .component('profile', {
    templateUrl: 'app/components/profile/profile.html',
    controller: ProfileController
  });

  ProfileController.$inject = ['user','toastr', '$uibModal','$rootScope','miscelaneos','Upload','api'];

  function ProfileController($user, toastr, $uibModal, $rootScope, $miscelaneos, Upload, $api) {
    var vm = this;
    vm.send = false;

    vm.$onInit = function() {
      vm.currentUser = $user.getCurrentUser();
      if(vm.currentUser.birthdate){
        vm.currentUser.birthdate = new Date(moment(vm.currentUser.birthdate, 'YYYY-MM-DD').add(1, 'days'))
      }else{
        vm.currentUser.birthdate = new Date(moment().add(-18, 'years'))
      }
    }

    vm.save = function(){
      if(Upload.isFile(vm.currentUser.avatar)){
        vm.currentUser.avatar.upload = Upload.upload({
          url: $api.getApiSPJ('estructura/update_profile'),
          data: vm.currentUser,
        })
        vm.currentUser.avatar.upload.then(function(res){
          vm.currentUser.avatar = res.data.data.avatar;
          $user.setCurrentUser(vm.currentUser);
          $rootScope.$broadcast('updateProfile');
          toastr.info(res.data.message);
          vm.send = false;
          vm.edit = false;
        }, function(err){
          vm.send = false;
          toastr.error(err.data.message);
        }, function (evt) {
          vm.currentUser.avatar.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
        });

      }else{
        $miscelaneos.updateProfile(vm.currentUser)
        .then(function(res){
          vm.send = false;
          $user.setCurrentUser(vm.currentUser);
          $rootScope.$broadcast('updateProfile');
          toastr.success(res.message);
          vm.edit = false;
        }, function(err){
          vm.send = false;
          toastr.error(err.data.message)
        })
      }
    }

    $rootScope.$on('updateProfile', function(ev, data){
      vm.currentUser = $user.getCurrentUser();
    })


    vm.validateAge = function(){
      vm.isAdult = moment().diff(vm.currentUser.birthdate, 'years');
    }

    function cancel() {
      $state.go('home');
    }

    vm.today = function() {
      vm.dt = new Date();
    };
    vm.today();

    vm.clear = function() {
      vm.dt = null;
    };

    vm.inlineOptions = {
      customClass: getDayClass,
      minDate: new Date(),
      showWeeks: false
    };

    vm.dateOptions = {
      dateDisabled: false,
      formatYear: 'yy',
      maxDate: new Date(2020, 5, 22),
      minDate: new Date(),
      startingDay: 1
    };

    vm.toggleMin = function() {
      vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
      vm.dateOptions.minDate = vm.inlineOptions.minDate;
    };
    vm.toggleMin();

    vm.open1 = function() {
      vm.popup1.opened = true;
    };

    vm.open2 = function() {
      vm.popup2.opened = true;
    };

    vm.setDate = function(year, month, day) {
      vm.dt = new Date(year, month, day);
    };

    vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    vm.format = vm.formats[0];
    vm.altInputFormats = ['M!/d!/yyyy'];

    vm.popup1 = {
      opened: false
    };

    vm.popup2 = {
      opened: false
    };

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var afterTomorrow = new Date();
    afterTomorrow.setDate(tomorrow.getDate() + 1);
    vm.events = [
      {
        date: tomorrow,
        status: 'full'
      },
      {
        date: afterTomorrow,
        status: 'partially'
      }
    ];

    function getDayClass(data) {
      var date = data.date,
        mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0,0,0,0);

        for (var i = 0; i < vm.events.length; i++) {
          var currentDay = new Date(vm.events[i].date).setHours(0,0,0,0);

          if (dayToCheck === currentDay) {
            return vm.events[i].status;
          }
        }
      }

      return '';
    }

  }

})();
