angular
.module('app')
.component('modalTicket', {
  templateUrl: 'app/components/quintico/modal-ticket.html',
  controller: ModalTicketController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  }
})

ModalTicketController.$inject = [];

function ModalTicketController(){
  var vm = this;
  vm.cancel = cancel;

  vm.$onInit = function(){
    vm.ticket = vm.resolve.ticket;
  }


  function cancel(){
    vm.dismiss({$value: 'cancel'});
  }

}
