angular
.module('app')
.component('modalCombiationsRifcar', {
  templateUrl: 'app/components/rifcar/modal-combinations.html',
  controller: ModalCombinationRifcarController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  }
})

ModalCombinationRifcarController.$inject = ['user', 'rifcarServices'];

function ModalCombinationRifcarController($user, $rifcarServices){
  var vm = this;
  vm.cancel = cancel;
  vm.cerrar = cerrar;
  vm.currentUser = $user.getCurrentUser();

  vm.$onInit = function(){
    vm.response = vm.resolve.response;
    vm.plays = vm.resolve.plays;

    vm.tickets = _.reduce(vm.response.negados, function(memo, data){
      _.forEach(vm.plays, function(rifa){
        var combinacion = _.findWhere(rifa.j, {i: data.i});
        if(combinacion){
          memo.push(combinacion)
        }
      })
      return memo;
    },[]);

  }

  vm.buyCombinations = function(){
    var data = {
      cod: vm.currentUser.id,
      ven: 1,
      ticket: vm.response.nt,
      app: 4,
      producto_id: 3
    }

    $rifcarServices.confirmSale(data)
    .then(function(res){
      vm.close({$value: res})
    })
  }


  vm.reversar = function() {
    var data = {
      cod: vm.currentUser.id,
      ven: 1,
      ticket: vm.response.nt,
    }
    $rifcarServices.revertSale(data)
    .then(function(res){
      vm.dismiss({$value: res})
    })
  }


  function cerrar(){
    vm.close({$value: 'Close'});
  }


  function cancel(){
    vm.dismiss({$value: 'cancel'});
  }

}
