(function () {
  'use strict';
  angular
  .module('app')
  .component('viewMessage', {
    templateUrl: 'app/components/messages/view-message.html',
    controller: ViewMessageController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ViewMessageController.$inject = ['user','toastr', '$uibModal','$rootScope','miscelaneos'];

  function ViewMessageController($user, toastr, $uibModal, $rootScope, $miscelaneos) {
    var vm = this;

    vm.$onInit = function(){
      vm.notification = vm.resolve.notification;
      $miscelaneos.toggleViewNotification(vm.notification);
    }

  }

})();
