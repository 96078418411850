angular
.module('app')
.component('modalConfirmPlaysZoologico', {
  templateUrl: 'app/components/modals/modalConfirmZoologico.html',
  controller: ModalConfirmPlaysZoologicooController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  }
})

ModalConfirmPlaysZoologicooController.$inject = [];

function ModalConfirmPlaysZoologicooController(){
  var vm = this;

  vm.plays = vm.resolve.plays;
  vm.playsDenied = vm.resolve.playsDenied;
  vm.cancel = cancel;
  vm.deletePlay = deletePlay;
  vm.confirm = confirm;

  _.forEach(vm.plays, function(draw){
	   draw.tickets = [];
   })
   _.forEach(vm.playsDenied.negados, function(ticketNegado){
     _.forEach(vm.plays, function(draw){
       var findTicket = _.findWhere(draw.j, {i: ticketNegado.i});
       if(findTicket){
         var ticket = ticketNegado;
         ticket.prettyTicket = findTicket;
         draw.tickets.push(ticket);
       }
     });
   });

  function confirm(){
    vm.close({$value: angular.copy(vm.playsDenied)});
  }

  function cancel(){
    vm.dismiss({$value: angular.copy(vm.playsDenied)});
  }

  function deletePlay(index){
    vm.plays.splice(index,1);
  }

}
