(function(){
  'use strict';

  angular
  .module('app')
  .filter('getDraw', getDraw);

  function getDraw(){

    return function(draw_id,draws){
      return _.filter(draws, function(value){
    		return value.id == draw_id;
    	})[0].name

    }

  }
})();
