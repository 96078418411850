(function () {
  'use strict';
  angular
  .module('app')
  .component('suggested', {
    templateUrl: 'app/components/my-suggested/list-my-suggested.html',
    controller: SuggestedController,
  });

  SuggestedController.$inject = ['$window', 'user','toastr','agentsPanelService','$rootScope','$stateParams'];

  function SuggestedController($window, $user, toastr, $agentsPanelService, $rootScope, $stateParams) {
    var vm = this;
    vm.currentUser = $user.getCurrentUser();

    vm.$onInit = function(){
      $agentsPanelService.getMySuggested()
      .then(function(res){
        vm.suggesteds = res;
      })
    }

  }

})();
