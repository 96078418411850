angular
.module('app')
.component('modalSigns', {
  templateUrl: 'app/components/modals/modalSigns.html',
  controller: ModalSingsController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  }
})

ModalSingsController.$inject = [];

function ModalSingsController(){
  var vm = this;
  vm.message = 'Hello world';
  vm.signs = vm.resolve.signs;
  vm.toggleAll = toggleAll;
  vm.cancel = cancel;
  vm.selectSigns = selectSigns;

  function toggleAll(){
    _.forEach(vm.signs, function(signo){
      signo.checked = !signo.checked;
    });
  }

  function selectSigns(){
    vm.close({$value: vm.signs});
  }

  function cancel(){
    vm.dismiss({$value: 'cancel'});
  }

}
