angular
  .module('app')
  .component('winners', {
    templateUrl: 'app/components/la_polla/winners.html',
    controller: WinnersController,
    bindings: {
      hippodromes: '<'
    }
  });

  WinnersController.$inject = ['user','ActionCableChannel','cashierService','$uibModal','$sce', 'pollaService','$timeout','$window','$rootScope'];

function WinnersController($user, ActionCableChannel, cashierService, $uibModal, $sce, $pollaService, $timeout, $window, $rootScope) {
  var vm = this;
  vm.color = '#000';
  vm.wait = 1;
  vm.getNotification = getNotification;
  
  vm.$onInit = function(){
    if ($window.localStorage.hasOwnProperty('cintillo')){
      vm.cintillo = JSON.parse($window.localStorage.getItem('cintillo'));
      $rootScope.$emit('updateCintillo', vm.cintillo);
      console.log(vm.cintillo)
    }else{
      vm.cintillo = undefined;
    }
    vm.currentUser = $user.getCurrentUser();
    vm.per = 20;
    vm.currentPage = 1;
    vm.selectHipo(vm.hippodromes[0])
    var channel = new ActionCableChannel("WinnersChannel", {});
    var callback = function(message){
      console.log(message)
      if(message.re_award){
        vm.selectHipo(vm.hippodromes[0]);
      }else{
        hippodrome_id = message.hippodrome_id;
        trackings = JSON.parse(message.pollas);
        re_award = message.re_award;
        var hipoSelected = _.findWhere(vm.hippodromes, {selected: true});
        if(hipoSelected && hipoSelected.id == hippodrome_id){
          vm.pollas = _.map(vm.pollas, function(polla){
            var tracking = _.findWhere(trackings, {id: polla.id});
            if(tracking){
              polla = tracking;
              var spanNumber = angular.element(document.querySelector('#polla-'+polla.id));
              spanNumber.addClass('fadeInUp');
              $timeout(function() {vm.wait=1;}, 500);
            }
            return polla;
          })
        }
      }
      

      
    };

    channel.subscribe(callback)
    .then(function(){
      console.log('Conectado al Canal')
      vm.sendToMyChannel = function(message){
        channel.send(message);
      };
    });
  }
  

  function getNotification(id){
    cashierService.getTransaction(id)
    .then(function(res){
      vm.ticket = $sce.trustAsHtml(angular.copy(res.detalle.replace(/\\n/g, '<br>')));
      vm.ticket_print = res.detalle;
      $timeout(function(){
        showTicket(vm.ticket, vm.ticket_print)
      },0)
    })
  }

  function showTicket(ticket, ticket_print){
    var modal = $uibModal.open({
      animation: true,
      component: 'gModalTicket',
      backdrop: 'static',
      windowClass: 'modalTicket',
      size: 'xs',
      resolve: {
        ticket: function() {
          return ticket;
        },
        ticket_print: function(){
          return ticket_print;
        }
      },
    });
  }

  vm.selectHipo = function(hipo){
    _.each(vm.hippodromes, function(h){
      h.selected = false;
    })
    hipo.selected = true;
    vm.hippodromeSelected = hipo;
    vm.loading = true;
    $pollaService.lastPollas(hipo.id)
    .then(function(res){
      vm.pollas = res.pollas;
      vm.total = res.total;
      vm.acumulado = res.acumulado;
      vm.pote_especial = res.pote_especial;
      vm.loading = false;
    });
  }

  vm.showAllPollas = function(){
    var hipo = _.findWhere(vm.hippodromes, {selected: true});
    $pollaService.allPollas({ page: 1, per: vm.per, hippodrome_id: hipo.id })
    .then(function(res){
      var modal = $uibModal.open({
        animation: true,
        component: 'allPollas',
        backdrop: 'static',
        windowClass: 'modalTicket',
        size: 'lg',
        resolve: {
          allPollas: function() {
            return res;
          },
          hippodrome_id: function(){
            return hipo.id;
          }
        },
      });
    });
  }



  
    

}
