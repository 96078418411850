(function(){
  'use strict';

  angular
  .module('app')
  .filter('prettyNamePanel', prettyNamePanel);

  function prettyNamePanel(){

    return function(tipo){
      return {
        "1": 'de Agente',
        "2": 'de Sub Agente',
        "3": 'C. Apuesta.',
        "4": 'Customer',
        "5": 'C. Apuestas.'
		  }[tipo];
    }

  }
})();
