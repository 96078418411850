(function() {
  'use strict';
  angular
    .module('app')
    .factory('firstRunService', firstRunService);

  firstRunService.$inject = ['$http', 'api'];

  function firstRunService($http, api) {
    
    function sale(data) {
      return $http.post(api.getUrlVentaCentinela('ventas/venta_first_run'), data)
      .then(function(res){
        return res.data;
      })
    }

    function confirmSale(data) {
      return $http.post(api.getUrlVentaCentinela('ventas/confirmar_venta'), data)
      .then(function(res){
        return res.data;
      })
    }

    function reverTicket(data) {
      return $http.post(api.getUrlVentaCentinela('ventas/reversar_venta'), data)
      .then(function(res){
        return res.data;
      })
    }

    function getLigaditos() {
      return [
        {
          image: '/images/ligadito/01.png',
          number: '01',
          selected: false,
          name: 'pez'
        },
        {
          image: '/images/ligadito/02.png',
          number: '02',
          selected: false,
          name: 'ancla'
        },
        {
          image: '/images/ligadito/03.png',
          number: '03',
          selected: false,
          name: 'trompo'
        },
        {
          image: '/images/ligadito/04.png',
          number: '04',
          selected: false,
          name:'copa'
        },
        {
          image: '/images/ligadito/05.png',
          number: '05',
          selected: false,
          name:'estrella'
        },
        {
          image: '/images/ligadito/06.png',
          number: '06',
          selected: false,
          name:'escalera'
        }
      ];
    }

    return {
      sale: sale,
      confirmSale: confirmSale,
      reverTicket: reverTicket,
      getLigaditos: getLigaditos
    };

  }
})();
