(function() {
  'use strict';
  angular
    .module('app')
    .factory('agentsPanelService', agentsPanelService);

  agentsPanelService.$inject = ['$http', 'api'];

  function agentsPanelService($http, api) {
    return {
      getMyPointSales: getMyPointSales,
      createPointSale: createPointSale,
      getMySuggested: getMySuggested,
      getMyPercentsBonus: getMyPercentsBonus,
      getMySubAgents: getMySubAgents,
      createSubAgents: createSubAgents,
      getMyPointSale: getMyPointSale,
      getStructurePercentsBonus: getStructurePercentsBonus,
      updatePointSale: updatePointSale,
      getReportCuadre: getReportCuadre
    };

    function hashToParams(params) {
      if(params){
        var params_url = '';
  
        Object.keys(params).forEach(function (item, index) {
          if (index == 0 && params[item] != null) {
            params_url += '?' + item + '=' + params[item];
          } else if (index != 0 && params[item] != null) {
            params_url += '&' + item + '=' + params[item];
          }
        });
        return params_url;
      }else{
        return null
      }

    }

    function getMyPointSales() {
      return $http.get(api.getApiSPJ('point_sales'))
      .then(function(res){
        return res.data;
      })
    }
    function getMyPointSale(id) {
      return $http.get(api.getApiSPJ('point_sales/'+id))
      .then(function(res){
        return res.data;
      })
    }

    
    function createPointSale(data) {
      return $http.post(api.getApiSPJ('point_sales'), data)
      .then(function(res){
        return res.data;
      })
    }
    function updatePointSale(data) {
      return $http.post(api.getApiSPJ('point_sales/update_point_sales'), data)
      .then(function(res){
        return res.data;
      })
    }


    

    function getMySuggested(){
      return $http.get(api.getApiSPJ('suggested'))
      .then(function(res){
        return res.data;
      })
    }

    function getMySubAgents() {
      return $http.get(api.getApiSPJ('sub_agentes'))
      .then(function(res){
        return res.data;
      })
    }
    function createSubAgents(data) {
      return $http.post(api.getApiSPJ('sub_agentes'), data)
      .then(function(res){
        return res.data;
      })
    }

    function getMyPercentsBonus(){
      return $http.get(api.getApiSPJ('estructura/get_configuracion_estructura'))
      .then(function(res){
        return res.data;
      })
    }

    function getStructurePercentsBonus(id){
      return $http.get(api.getApiSPJ('estructura/get_configuracion_estructura_id?estructura_id='+id))
      .then(function(res){
        return res.data;
      })
    }
    function getReportCuadre(data){
      return $http.get(api.getApiSPJ('report'+hashToParams(data)))
      .then(function(res){
        return res.data;
      })
    }

    

  }
})();
