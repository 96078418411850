(function () {
  'use strict';
  angular
    .module('app')
    .directive('loadFullScreen', loadFullScreen);

  loadFullScreen.$inject = ['user'];

  function loadFullScreen($user) {
    return {
      link: link
    };

    function link(scope, el, attrs, ctrl) {
      var currentUser = $user.getCurrentUser();
      var element = document.documentElement;

      if (currentUser.tipo === 5) {
        var viewFullScreen = document.getElementById("fullScreen");
        if (viewFullScreen) {
          viewFullScreen.addEventListener("click", function () {
            var docElm = document.documentElement;
            if (docElm.requestFullscreen) {
              docElm.requestFullscreen();
            } else if (docElm.msRequestFullscreen) {
              docElm.msRequestFullscreen();
            } else if (docElm.mozRequestFullScreen) {
              docElm.mozRequestFullScreen();
            } else if (docElm.webkitRequestFullScreen) {
              docElm.webkitRequestFullScreen();
            }
          })
        }
      }

    }
  }
})();
