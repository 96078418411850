(function() {
  'use strict';
  angular
    .module('app')
    .factory('animalitoService', animalitoService);

  animalitoService.$inject = ['$http', 'api'];

  function animalitoService($http, api) {

    function sale(data) {
      return $http.post(api.getUrlVentaCentinela('ventas/nueva_venta_v2'), data)
      .then(function(res){
        return res.data;
      })
    }

    function confirmSaleZoo(data) {
      return $http.post(api.getUrlVentaCentinela('ventas/confirmar_venta'), data)
      .then(function(res){
        return res.data;
      })
    }

    function reverTicket(data) {
      return $http.post(api.getUrlVentaCentinela('ventas/reversar_venta'), data)
      .then(function(res){
        return res.data;
      })
    }

    function getAnimals(direcoty) {
      return [
        {
          image: '/images/'+direcoty+'/01.png',
          number: '01',
          selected: false,
          name:  'Carnero',
          color: 'red'
        },
        {
          image: '/images/'+direcoty+'/02.png',
          number: '02',
          selected: false,
          name:  'Toro',
          color: 'black'
        },
        {
          image: '/images/'+direcoty+'/03.png',
          number: '03',
          selected: false,
          name:  'Ciempies',
          color: 'red'
        },
        {
          image: '/images/'+direcoty+'/04.png',
          number: '04',
          selected: false,
          name: 'Alacran',
          color: 'black'
        },
        {
          image: '/images/'+direcoty+'/05.png',
          number: '05',
          selected: false,
          name: 'Leon',
          color: 'red'
        },
        {
          image: '/images/'+direcoty+'/06.png',
          number: '06',
          selected: false,
          name: 'Rana',
          color: 'black'
        },
        {
          image: '/images/'+direcoty+'/07.png',
          number: '07',
          selected: false,
          name: 'Perico',
          color: 'red'
        },
        {
          image: '/images/'+direcoty+'/08.png',
          number: '08',
          selected: false,
          name: 'Raton',
          color: 'black'
        },
        {
          image: '/images/'+direcoty+'/09.png',
          number: '09',
          selected: false,
          name: 'Aguila',
          color: 'red'
        },
        {
          image: '/images/'+direcoty+'/10.png',
          number: '10',
          selected: false,
          name: 'Tigre',
          color: 'black'
        },
        {
          image: '/images/'+direcoty+'/11.png',
          number: '11',
          selected: false,
          name: 'Gato',
          color: 'black'
        },
        {
          image: '/images/'+direcoty+'/12.png',
          number: '12',
          selected: false,
          name: 'Caballo',
          color: 'red'
        },
        {
          image: '/images/'+direcoty+'/13.png',
          number: '13',
          selected: false,
          name: 'Mono',
          color: 'black'
        },
        {
          image: '/images/'+direcoty+'/14.png',
          number: '14',
          selected: false,
          name: 'Paloma',
          color: 'red'
        },
        {
          image: '/images/'+direcoty+'/15.png',
          number: '15',
          selected: false,
          name: 'Zorro',
          color: 'black'
        },
        {
          image: '/images/'+direcoty+'/16.png',
          number: '16',
          selected: false,
          name: 'Oso',
          color: 'red'
        },
        {
          image: '/images/'+direcoty+'/17.png',
          number: '17',
          selected: false,
          name: 'Pavo',
          color: 'black'
        },
        {
          image: '/images/'+direcoty+'/18.png',
          number: '18',
          selected: false,
          name: 'Burro',
          color: 'red'
        },
        {
          image: '/images/'+direcoty+'/19.png',
          number: '19',
          selected: false,
          name: 'Chivo',
          color: 'red'
        },
        {
          image: '/images/'+direcoty+'/20.png',
          number: '20',
          selected: false,
          name: 'Cochino',
          color: 'black'
        },
        {
          image: '/images/'+direcoty+'/21.png',
          number: '21',
          selected: false,
          name: 'Gallo',
          color: 'red'
        },
        {
          image: '/images/'+direcoty+'/22.png',
          number: '22',
          selected: false,
          name: 'Camello',
          color: 'black'
        },
        {
          image: '/images/'+direcoty+'/23.png',
          number: '23',
          selected: false,
          name: 'Zebra',
          color: 'red'
        },
        {
          image: '/images/'+direcoty+'/24.png',
          number: '24',
          selected: false,
          name: 'Iguana',
          color: 'black'
        },
        {
          image: '/images/'+direcoty+'/25.png',
          number: '25',
          selected: false,
          name: 'Gallina',
          color: 'red'
        },
        {
          image: '/images/'+direcoty+'/26.png',
          number: '26',
          selected: false,
          name: 'Vaca',
          color: 'black'
        },
        {
          image: '/images/'+direcoty+'/27.png',
          number: '27',
          selected: false,
          name: 'Perro',
          color: 'red'
        },
        {
          image: '/images/'+direcoty+'/28.png',
          number: '28',
          selected: false,
          name: 'Zamuro',
          color: 'black'
        },
        {
          image: '/images/'+direcoty+'/29.png',
          number: '29',
          selected: false,
          name: 'Elefante',
          color: 'black'
        },
        {
          image: '/images/'+direcoty+'/30.png',
          number: '30',
          selected: false,
          name: 'Caiman',
          color: 'red'
        },
        {
          image: '/images/'+direcoty+'/31.png',
          number: '31',
          selected: false,
          name: 'Lapa',
          color: 'black'
        },
        {
          image: '/images/'+direcoty+'/32.png',
          number: '32',
          selected: false,
          name: 'Ardilla',
          color: 'red'
        },
        {
          image: '/images/'+direcoty+'/33.png',
          number: '33',
          selected: false,
          name: 'pescado',
          color: 'black'
        },
        {
          image: '/images/'+direcoty+'/34.png',
          number: '34',
          selected: false,
          name: 'Venado',
          color: 'red'
        },
        {
          image: '/images/'+direcoty+'/35.png',
          number: '35',
          selected: false,
          name: 'Jirafa',
          color: 'black'
        },
        {
          image: '/images/'+direcoty+'/36.png',
          number: '36',
          selected: false,
          name: 'Culebra',
          color: 'red'
        },{
          image: '/images/'+direcoty+'/0.png',
          number: '0',
          selected: false,
          name: 'Delfin',
          color: 'green'
        },
        {
          image: '/images/'+direcoty+'/00.png',
          number: '00',
          selected: false,
          name: 'Ballena',
          color: 'green'
        },
        
      ]
    }

    return {
      sale: sale,
      confirmSaleZoo: confirmSaleZoo,
      reverTicket: reverTicket,
      getAnimals: getAnimals
    };

  }
})();
