(function(){
  'use strict';

  angular
  .module('app')
  .component('loadingModal', {
    templateUrl: 'app/components/modals/loadingModal.html',
    controller: LoadingModalController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  })

  LoadingModalController.$inject = ['user', 'toastr', 'moment','$window','$rootScope','authService'];

  function LoadingModalController($user, toastr, moment, $window, $rootScope, $authService) {
    var vm = this;

    vm.$onInit = function(){
      if(vm.resolve.tipo == 1){
        vm.message = 'Solicitando informacion del punto...';
        $authService.goToPS()
        .then(function(res){
          vm.message = 'Validando datos...';
          vm.currentUser = res.estructura;
          $user.setCurrentUser(vm.currentUser);
          setTimeout(function () {
            $rootScope.$broadcast('updatedUser', vm.currentUser);
            vm.message = 'Redireccionando...';
            setTimeout(function () {
              vm.close({$value: vm.currentUser});
            }, 500);
          }, 500);
        })
      }

      if(vm.resolve.tipo == 2){
        vm.message = 'Solicitando informacion del punto...';
        $authService.backToCDA()
        .then(function(res){
          vm.message = 'Validando datos...';
          vm.currentUser = res.estructura;
          $user.setCurrentUser(vm.currentUser);
          setTimeout(function () {
            $rootScope.$broadcast('updatedUser', vm.currentUser);
            vm.message = 'Redireccionando...';
            setTimeout(function () {
              vm.close({$value: vm.currentUser});
            }, 500);
          }, 500);
        })
      }
    }

  }
})();
