(function () {
  'use strict';
  angular
  .module('app')
  .component('reportAgents', {
    template: '<div ng-include="$ctrl.urlTemplate"></div>',
    controller: ReportAgentsController,
  });

  ReportAgentsController.$inject = ['user','agentsPanelService','miscelaneos'];

  function ReportAgentsController($user, $agentsPanelService, $miscelaneos) {
    var vm = this;
    vm.currentUser = $user.getCurrentUser();

    vm.$onInit = function(){
      vm.urlTemplate = 'app/components/report-agents/report-agents.html';
      vm.from = moment().format('YYYY-MM-DD');
      vm.to = moment().format('YYYY-MM-DD');
      vm.imagenes = $miscelaneos.getImagesToBonus();
      $agentsPanelService.getReportCuadre({date_to: vm.to, date_from: vm.from})
      .then(function(res){
        vm.reports = _.reject(res, function(item){
          return item.loteria_id == 1;
        })

        _.map(vm.reports, function(data){
          var image = _.findWhere(vm.imagenes, {loteria_id: data.loteria_id});
          data.image = image ? image.image : null;
          return data;          
        })

        vm.total = _.reduce(vm.reports, function(memo, data){
          memo.total_venta += parseFloat(data.venta);
          memo.total_premio += parseFloat(data.premio);
          memo.total_comision += parseFloat(data.comision);
          return memo;
        },{
          total_venta: 0,
          total_premio: 0,
          total_comision: 0,
        })
      })
    }

    vm.consult = function(){
      vm.reports = [];
      vm.total = {};
      $agentsPanelService.getReportCuadre({date_to: moment(vm.date_to).format('YYYY-MM-DD'), date_from: moment(vm.date_from).format('YYYY-MM-DD')})
      .then(function(res){
        vm.reports = _.reject(res, function(item){
          return item.loteria_id == 1;
        })

        _.map(vm.reports, function(data){
          var image = _.findWhere(vm.imagenes, {loteria_id: data.loteria_id});
          data.image = image ? image.image : null;
          return data;          
        })

        vm.total = _.reduce(vm.reports, function(memo, data){
          memo.total_venta += parseFloat(data.venta);
          memo.total_premio += parseFloat(data.premio);
          memo.total_comision += parseFloat(data.comision);
          return memo;
        },{
          total_venta: 0,
          total_premio: 0,
          total_comision: 0,
        })
      })

    }


    vm.today = function() {
      vm.date_from = new Date();
      vm.date_to = new Date();
    };
    vm.today();

    vm.clear = function() {
      vm.dt = null;
    };

    vm.inlineOptions = {
      customClass: getDayClass,
      minDate: new Date(),
      showWeeks: false
    };

    vm.dateOptions = {
      dateDisabled: false,
      formatYear: 'yy',
      // maxDate: new Date(2020, 5, 22),
      minDate: new Date(),
      startingDay: 1,
      showWeeks: false,
      showButtonBar: false,
    };

    vm.toggleMin = function() {
      vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
      vm.dateOptions.minDate = vm.inlineOptions.minDate;
    };
    vm.toggleMin();

    vm.open1 = function() {
      vm.popup1.opened = true;
    };

    vm.open2 = function() {
      vm.popup2.opened = true;
    };

    vm.setDate = function(year, month, day) {
      vm.dt = new Date(year, month, day);
    };

    vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    vm.format = vm.formats[0];
    vm.altInputFormats = ['M!/d!/yyyy'];

    vm.popup1 = {
      opened: false
    };

    vm.popup2 = {
      opened: false
    };

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var afterTomorrow = new Date();
    afterTomorrow.setDate(tomorrow.getDate() + 1);
    vm.events = [
      {
        date: tomorrow,
        status: 'full'
      },
      {
        date: afterTomorrow,
        status: 'partially'
      }
    ];

    function getDayClass(data) {
      var date = data.date,
        mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0,0,0,0);

        for (var i = 0; i < vm.events.length; i++) {
          var currentDay = new Date(vm.events[i].date).setHours(0,0,0,0);

          if (dayToCheck === currentDay) {
            return vm.events[i].status;
          }
        }
      }

      return '';
    }

    
  }

})();
