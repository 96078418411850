(function(){
  'use strict';

  /**
   * @ngdoc service
   * @name app.api
   * @description
   * # api
   * Factory in the app.
   */
  angular.module('app')
  .factory('api', function (BASE_URL, CENTINELA_URL) {

    return {
      getCentinelaApi: function (request) {
        return CENTINELA_URL + '/centinela/api/v1/' + request;
      },
      getUrlVentaCentinela: function (request) {
        return CENTINELA_URL +'/centinela/api/v1/'+request;
      },
      getUrl: function (request) {
        return BASE_URL + '/api/' + request;
      },
      getApiSPJ: function (request) {
        return BASE_URL + '/api/v1/' + request;
      }

    };
  });

})();
