angular
.module('app')
.component('modalCombiations', {
  templateUrl: 'app/components/quintico/modal-combinations.html',
  controller: ModalCombinationController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  }
})

ModalCombinationController.$inject = ['user', 'QuinticoServices'];

function ModalCombinationController($user, $QuinticoServices){
  var vm = this;
  vm.cancel = cancel;
  vm.cerrar = cerrar;
  vm.currentUser = $user.getCurrentUser();

  vm.$onInit = function(){
    vm.response = vm.resolve.response;
    vm.listCombinationsToPlay = vm.resolve.listCombinationsToPlay;
    vm.tickets = _.reduce(vm.response.negados, function(memo, data){
      combinacion = _.findWhere(vm.listCombinationsToPlay, {i: data.i});
      if(combinacion){
        combinacion.noAvailable = true;
        memo.push(combinacion)
      }else{
        combinacion.noAvailable = false;
        memo.push(data)
      }
      return memo;
    },[]);


  }

  vm.buyCombinations = function(){
    var data = {
      cod: vm.currentUser.id,
      ven: 1,
      ticket: vm.response.nt,
      app: 4,
      producto_id: 3
    }

    $QuinticoServices.confirmSale(data)
    .then(function(res){
      vm.close({$value: res})
    })
  }


  vm.reversar = function() {
    var data = {
      cod: vm.currentUser.id,
      ven: 1,
      ticket: vm.response.nt,
    }
    $QuinticoServices.revertSale(data)
    .then(function(res){
      vm.dismiss({$value: res})
    })
  }


  function cerrar(){
    vm.close({$value: 'Close'});
  }


  function cancel(){
    vm.dismiss({$value: 'cancel'});
  }

}
