angular
.module('app')
.component('allPollas', {
  templateUrl: 'app/components/la_polla/all_pollas.html',
  controller: ShowAllPollaController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  }
})

ShowAllPollaController.$inject = ['$window','user','pollaService'];

function ShowAllPollaController($window,$user,$pollaService){
  var vm = this;
  vm.cancel = cancel;

  vm.$onInit = function(){
    vm.currentUser = $user.getCurrentUser();
    vm.allPollas = vm.resolve.allPollas.data;
    vm.pollas = _.reduce(vm.allPollas, function(memo, data){
      if(_.uniq(_.pluck(data.races, 'index')).length == 1){
        memo.push(data);
      }else{
        var agruped = _.groupBy(data.races, 'index');
        var polla = _.map(Object.values(agruped), function(races){
          return {
            id: data.id,
            codagen: data.codagen,
            oeration_id: data.oeration_id,
            races: races,
            stud: data.stud,
          }
        })
        memo = memo.concat(polla);
      }
      return memo;
    },[]);
    vm.data = vm.resolve.allPollas;
    vm.hippodrome_id = vm.resolve.hippodrome_id;
    vm.per = 20;
    vm.currentPage = 1;
  }

  vm.pageChanged = function () {
    vm.send = true;
    vm.loadingData = true;
    vm.currentPage = vm.data.current_page;
    console.log(vm.data.data)
    $pollaService.allPollas({ page: vm.currentPage, per: vm.per, hippodrome_id: vm.hippodrome_id })
    .then(function(res){
      vm.data = res;
    })
  };

  function cancel(){
    vm.dismiss({$value: 'cancel'});
  }

}
