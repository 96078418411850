(function(){
  'use strict';

  angular
  .module('app')
  .filter('getStatusPretty', getStatusPretty);

  function getStatusPretty(){

    return function(status){
      return {'0': 'Disponible',
	    '1': 'Comprado',
	    '2': 'Reservado',
	    'G': 'Ganador',
	    'V': 'Vendido',
	    'P': 'Pagado'
		  }[status];
    }

  }
})();
