(function(){
  'use stric';

  angular
  .module('app')
  .component('gamesAccess', {
    templateUrl: 'app/components/games-access/games-access.html',
    controller: GamesPublicityController
  })

  function GamesPublicityController($state){
    var vm = this;
    vm.currentState = $state.current.name;

    vm.games = [
      {
        image: '/images/SPJmicro_tuquintico.png',
        link: 'quintico',
        name: 'Tu Quintico',
        show: false
      },{
        image: '/images/SPJmicro_zoologico.png',
        link: 'zoologico',
        name: 'Zoologico de la suerte',
        show: false
      },{
        image: '/images/SPJmicro_ligadito.png',
        link: 'ligadito',
        name: 'Ligadito',
        show: false
      },{
        image: '/images/SPJmicro_rifcar.png',
        link: 'rifcar',
        name: 'Rifcar',
        show: false
      },
    ];
  }
})();
