// import angular from "angular";
// import * as Sentry from "@sentry/browser";
// import { Angular as AngularIntegration } from "@sentry/integrations";


angular
  .module('app', [
    'ui.router',
    'ngAnimate',
    'ngSanitize',
    'ui.bootstrap',
    'vcRecaptcha',
    'toastr',
    'ui.select',
    'ngFileUpload',
    'angular-button-spinner',
    'ngActionCable',
    // 'monospaced.qrcode',
    // 'ngSentry',
    'ds.clock'
  ]);
