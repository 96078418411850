(function () {
  'use strict';
  angular
    .module('app')
    .directive('hideMenuBar', hideMenuBar);

  hideMenuBar.$inject = [];

  function hideMenuBar() {
    return {
      link: link
    };

    function link(scope, el, attrs, ctrl) {
      $(el).click(function(){
        $('body').toggleClass('sidebar-open')
      })
    }
  }
})();
