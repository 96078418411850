
angular
.module('app')
.component('adminLayout', {
  templateUrl: 'app/components/layouts/adminLayout.html',
  controller: adminLayoutController
});

function adminLayoutController() {
  this.text = 'My brand new component!';
}
