(function() {
  'use strict';
  angular
    .module('app')
    .factory('loteriesService', loteriesService);

  loteriesService.$inject = ['$http', 'api'];

  function loteriesService($http, api) {

    function sale(data) {
      return $http.post(api.getUrlVentaCentinela('ventas/nueva_venta_v2'), data)
      .then(function(res){
        return res.data;
      })
    }

    function confirmSale(data) {
      return $http.post(api.getUrlVentaCentinela('ventas/confirmar_venta'), data)
      .then(function(res){
        return res.data;
      })
    }

    function reverTicket(data) {
      return $http.post(api.getUrlVentaCentinela('ventas/reversar_venta'), data)
      .then(function(res){
        return res.data;
      })
    }


    return {
      sale: sale,
      confirmSale: confirmSale,
      reverTicket: reverTicket
    };

  }
})();
