(function() {
  'use strict';
  angular
    .module('app')
    .factory('QuinticoServices', QuinticoServices);

  QuinticoServices.$inject = ['$http', 'api'];

  function QuinticoServices($http, api) {
    return {
      getDraws: getDraws,
      getSigns: getSigns,
      buyCombinations: buyCombinations,
      getMethodsPayments: getMethodsPayments,
      getRandomCombination: getRandomCombination,
      confirmSale: confirmSale,
      revertSale: revertSale,
      getExamplesPayments: getExamplesPayments
    };

    function getDraws() {
      return $http.get(api.getCentinelaApi('configuracion/get_sorteo_quintico'))
      .then(function(res){
        return res.data;
      })
    }

    function buyCombinations(data) {
      return $http.post(api.getCentinelaApi('quintico/nueva_venta_quintico'),data)
      .then(function(res){
        return res.data;
      })
    }

    function getMethodsPayments(){
      return $http.get(api.getCentinelaApi('configuracion/get_categorias_quintico'))
      .then(function(res){
        return res.data;
      })
    }

    function confirmSale(data){
      return $http.post(api.getCentinelaApi('quintico/confirmar_venta'), data)
      .then(function(res){
        return res.data;
      })
    }
    function revertSale(data){
      return $http.post(api.getCentinelaApi('quintico/reversar_venta'), data)
      .then(function(res){
        return res.data;
      })
    }

    function getRandomCombination(draw_id){
      return $http.post(api.getCentinelaApi('tickets/random'), draw_id)
      .then(function(res){
        return res.data
      })
    }

    function getSigns() {
            return [
        {
          name: "Aries",
          imagen: "/images/signos/aries.png",
          new_image: "/images/signos/aries-gray.png",
          checked: false,
          available: true,
          code: 1
        },
        {
          name: "Tauro",
          imagen: "/images/signos/tauro.png",
          new_image: "/images/signos/tauro-gray.png",
          checked: false,
          available: true,
          code: 2
        },
        {
          name: "Geminis",
          imagen: "/images/signos/geminis.png",
          new_image: "/images/signos/geminis-gray.png",
          checked: false,
          available: true,
          code: 3
        },
        {
          name: "Cancer",
          imagen: "/images/signos/cancer.png",
          new_image: "/images/signos/cancer-gray.png",
          checked: false,
          available: true,
          code: 4
        },
        {
          name: "Leo",
          imagen: "/images/signos/leo.png",
          new_image: "/images/signos/leo-gray.png",
          checked: false,
          available: true,
          code: 5
        },
        {
          name: "Virgo",
          imagen: "/images/signos/virgo.png",
          new_image: "/images/signos/virgo-gray.png",
          checked: false,
          available: true,
          code: 6
        },
        {
          name: "Libra",
          imagen: "/images/signos/libra.png",
          new_image: "/images/signos/libra-gray.png",
          checked: false,
          available: true,
          code: 7
        },
        {
          name: "Escorpio",
          imagen: "/images/signos/escorpio.png",
          new_image: "/images/signos/escorpio-gray.png",
          checked: false,
          available: true,
          code: 8
        },
        {
          name: "Sagitario",
          imagen: "/images/signos/sagitario.png",
          new_image: "/images/signos/sagitario-gray.png",
          checked: false,
          available: true,
          code: 9
        },
        {
          name: "Capricornio",
          imagen: "/images/signos/capricornio.png",
          new_image: "/images/signos/capricornio-gray.png",
          checked: false,
          available: true,
          code: 10
        },
        {
          name: "Acuario",
          imagen: "/images/signos/acuario.png",
          new_image: "/images/signos/acuario-gray.png",
          checked: false,
          available: true,
          code: 11
        },
        {
          name: "Piscis",
          imagen: "/images/signos/piscis.png",
          new_image: "/images/signos/piscis-gray.png",
          checked: false,
          available: true,
          code: 12
        }
      ]
    }
    function getExamplesPayments(){
      return [
        {
          code: '01',
          digit1: true,
          digit2: true,
          digit3: true,
          digit4: true,
          digit5: true,
          signo: true,
        },{
          code: '02',
          digit1: true,
          digit2: true,
          digit3: true,
          digit4: true,
          digit5: false,
          signo: true,
        },{
          code: '03',
          digit1: true,
          digit2: true,
          digit3: true,
          digit4: false,
          digit5: false,
          signo: true,
        },{
          code: '04',
          digit1: true,
          digit2: true,
          digit3: false,
          digit4: false,
          digit5: false,
          signo: true,
        },{
          code: '05',
          digit1: true,
          digit2: false,
          digit3: false,
          digit4: false,
          digit5: false,
          signo: true,
        },{
          code: '06',
          digit1: true,
          digit2: true,
          digit3: true,
          digit4: true,
          digit5: true,
          signo: false,
        },{
          code: '07',
          digit1: true,
          digit2: true,
          digit3: true,
          digit4: true,
          digit5: false,
          signo: false,
        },{
          code: '08',
          digit1: true,
          digit2: true,
          digit3: true,
          digit4: false,
          digit5: false,
          signo: false,
        },{
          code: '09',
          digit1: true,
          digit2: true,
          digit3: false,
          digit4: false,
          digit5: false,
          signo: false,
        },

      ]
    }
  }
})();
