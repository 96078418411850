angular
.module('app')
.component('confirmCustomer', {
  templateUrl: 'app/components/confirm_customer/confirm_customer.html',
  controller: ConfirmCustomerController
});

ConfirmCustomerController.$inject = ['$log','SingUpService','$window','$stateParams','$state'];

function ConfirmCustomerController($log, $SingUpService, $window, $stateParams, $state) {
  var vm = this;
  vm.send = false;
  vm.token = $stateParams.token
  vm.save = save;
  vm.save();

  function save(){
    $SingUpService.confirmCustomer(vm.token)
    .then(function(res){
      $window.swal('Usuario activado con exito',res.message, 'success');
      $state.go('home');
    }, function(err){
      if(err.status === 404){
        $window.swal('Error!','Código de verificación no enviado','error');
        return;
      }
      $window.swal('Error!',err.data.message,'error');
    })
  }

}
