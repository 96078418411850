angular
.module('app')
.component('unsubscribe', {
  templateUrl: 'app/components/unsubscribe/unsubscribe.html',
  controller: UnsubscribeController
});

UnsubscribeController.$inject = ['$log','miscelaneos','$window','$stateParams','$state'];

function UnsubscribeController($log, $miscelaneos, $window, $stateParams, $state) {
  var vm = this;
  vm.send = false;
  vm.id = $stateParams.id

  vm.$onInit = function(){
    $miscelaneos.unsubscribe(vm.id)
    .then(function(res){
      $window.swal("Te extrañaremos :/",res.message, 'success');
      $state.go('home');
    }, function(err){
      $window.swal("Error",err.data.message,'error');
      return;
    })
  }

}
