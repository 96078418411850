(function() {
  'use strict';
  angular
    .module('app')
    .factory('pollaService', pollaService);

  pollaService.$inject = ['$http', 'api'];

  function pollaService($http, api) {

    function sale(data) {
      return $http.post(api.getUrlVentaCentinela('polla/nueva_venta'), data)
      .then(function(res){
        return res.data;
      })
    }

    function lastPollas(id){
      return $http.get(api.getApiSPJ('polla/find_lasts_studs?hippodrome_id='+id))
      .then(function(res){
        return res.data;
      })
    }

    function allPollas(data){
      return $http.get(api.getApiSPJ('polla.json' + hashToParams(data)))
      .then(function(res){
        return res.data;
      })
    }

    function viewWinnersTable(id){
      return $http.get(api.getApiSPJ('polla/show_winners_table'))
      .then(function(res){
        return res.data;
      })
    }



    function hashToParams(params) {
      if(params){
        var params_url = '';
  
        Object.keys(params).forEach(function (item, index) {
          if (index == 0 && params[item] != null) {
            params_url += '?' + item + '=' + params[item];
          } else if (index != 0 && params[item] != null) {
            params_url += '&' + item + '=' + params[item];
          }
        });
        return params_url;
      }else{
        return '';
      }

    }


    return {
      sale: sale,
      lastPollas: lastPollas,
      allPollas: allPollas,
      viewWinnersTable: viewWinnersTable
    };

  }
})();
