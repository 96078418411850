(function () {
  'use strict';
  angular
  .module('app')
  .component('tutoDeposit', {
    templateUrl: 'app/components/tuto-deposit/tuto-deposit.html',
    controller: TutoDepositController
  });

  TutoDepositController.$inject = ['$state', 'api', 'user','toastr','miscelaneos','Upload', 'cashierService','$rootScope','$uibModal','$window'];

  function TutoDepositController($state, $api, $user, toastr, $miscelaneos, Upload, $cashierService, $rootScope, $uibModal,$window) {
    var vm = this;
    
    vm.$onInit = function() {
      vm.currentUser = $user.getCurrentUser();
      $miscelaneos.getSettingCajeroWeb()
      .then(function(res){
        vm.settingCajeroWeb = res;
      })
      $miscelaneos.getBanksOperadora()
      .then(function(res){
        vm.bancos_operadora = _.map(res, function(data){
          data.active = false;
          data.otros = angular.fromJson(data.otros);
          return data
        });
      })
      $miscelaneos.getDefaultSolicitudOperacion()
      .then(function(res){
        vm.default = res;
      })
    }

  }

})();
