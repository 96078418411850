(function() {
  'use strict';
  angular
    .module('app')
    //  .constant('BASE_URL', 'http://localhost:4007')
    //  .constant('DOMAIN_URL', 'http://localhost:3004')
    //  .constant('CENTINELA_URL', 'http://localhost:4004')
    //  .constant('OPERADORA_URL', 'http://localhost:4000')
    //************ With SSL
    .constant('BASE_URL', 'https://www.realparley.com')
    .constant('DOMAIN_URL', 'https://www.realparley.com')
    .constant('CENTINELA_URL', 'https://www.realparley.com')
    .constant('OPERADORA_URL', 'https://www.realparley.com')
    .constant('_', _)
    .constant('accounting', accounting)
    .constant('swal', 'swal')
    .constant('moment', moment);
})();
