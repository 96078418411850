(function () {
  'use strict';
  angular
  .module('app')
  .component('lobbyUser', {
    templateUrl: 'app/components/lobby-user/lobby-user.html',
    controller: LobbyUserController
  });

  LobbyUserController.$inject = ['$timeout', '$window', 'user','toastr','cashierService','$uibModal','$rootScope','miscelaneos'];

  function LobbyUserController($timeout, $window, $user, toastr, cashierService, $uibModal, $rootScope, $miscelaneos) {
    var vm = this;
    vm.loadData = true;
    vm.addBank = addBank;
    vm.withdraw = withdraw;
    vm.getBanksStructure = getBanksStructure;
    vm.viewTransaction = viewTransaction;
    vm.editBank = editBank;
    vm.showHistory = showHistory;
    vm.sendReferenca = sendReferenca;


    vm.$onInit = function() {
      vm.loadingCajeroWeb = true;
      vm.currentUser = $user.getCurrentUser();
      cashierService.getCajerWeb(vm.currentUser.cajero.id)
      .then(function(res){
        vm.loadingCajeroWeb = false;
        vm.currentUser.cajero = res;
        $user.setCurrentUser(vm.currentUser);
        $rootScope.$broadcast('updatedUser', vm.currentUser);
        vm.getBanksStructure();
      });
      $miscelaneos.getDefaultSolicitudOperacion()
      .then(function(res){
        vm.default = res;
      });

      cashierService.getPendingCash()
      .then(function(res){
        vm.total_effecty = res;
      })
    }

    function getBanksStructure(){
      $miscelaneos.getBanksStructure()
      .then(function(res){
        vm.banksStructure = res;
        if(vm.bankSelected){
          vm.bankSelected = _.findWhere(vm.banksStructure, {id: vm.bankSelected.id});
        }
      })
    }

    function viewTransaction(data){
      var modal = $uibModal.open({
        animation: true,
        component: 'detailTransaction',
        windowClass: 'modalDetailTransaction',
        size: 'xs',
        resolve: {
          detail_transaction: function(){
            return data;
          }
        }
      });
    }

    function sendReferenca(){
      cashierService.getVipRequest()
      .then(function(res){
        if(res != null){
          swal({
            title: 'Indique el numero de referencia para la solicitud con monto: ' + res.monto,
            input: 'text',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Enviar',
            showLoaderOnConfirm: true,
            preConfirm: function(referencia) {
              return cashierService.updateVipRequest({referencia: referencia, solicitud_id: res.id})
              .then(function(res){
                return res;
              }, function(err){
                swal.showValidationError(err.data.message);
              })
            }
          }).then(function(result){
            if(result.value){
              toastr.success(result.value.message);
              vm.$onInit();
            }
          })
        }else{
          toastr.warning('No hay solicitudes pendientes por referencia');
        }
      })
      
    }

    function withdraw(){
      if(parseFloat(vm.default.monto_minimo_retiro) > parseFloat(vm.retiro.monto)){
        $window.swal('Error','El monto minimo para retiros es de '+vm.default.monto_minimo_retiro,'warning');
      }else{
        if(!vm.currentUser.cajero.es_credito){
          if(parseFloat(vm.retiro.monto) > parseFloat(vm.currentUser.cajero.saldo_actual)){
            $window.swal('error','El monto a retirar no debe mayor al saldo actual','error');
            return
          }else{
            generateTransaction()
          }
        }else{
          generateTransaction()
        }
      }
    }

    function addBank(){
      var modal = $uibModal.open({
        animation: true,
        component: 'addBank',
        backdrop: 'static',
        windowClass: 'modalAddBank',
        size: 'xs',
      });
      // modal.result.then(function(res){})
    }

    function editBank(bank){
      var modal = $uibModal.open({
        animation: true,
        component: 'addBank',
        backdrop: 'static',
        windowClass: 'modalAddBank',
        size: 'xs',
        resolve: {
          bank: function(){
            return angular.copy(bank);
          }
        }
      });
    }

    function showHistory(){
      vm.loadData = true;
      cashierService.getHistory(vm.currentUser.cajero.id)
      .then(function(res){
        vm.transactions = undefined;
        vm.transactions = res;
        vm.loadData = false;
      })
    }


    $timeout(function(){
      getLastTransaction();

      cashierService.getCoupon()
      .then(function(res){
        vm.coupon = res;
        if(vm.coupon != null){
          vm.coupon.pretty_expire = moment.unix(res.expire).format('DD/MM/YYYY');
        }
      })
    },1000)


    function getLastTransaction(){
      cashierService.getLastTransactions(vm.currentUser.cajero.id)
      .then(function(res){
        vm.transactions = res;
        vm.loadData = false;
      })
    }

    function generateTransaction(){
      vm.send = true;
          var data = {
            banco_origin: null,
            banco_receptor: vm.bankSelected.id,
            monto: vm.retiro.monto,
            detalle: vm.retiro.detalle,
            referencia: null,
            file: null,
            tipo: 0,
            status: 0,
            estructura_envia: vm.currentUser.id,
            estructura_recibe: null,
          }
          $window.swal({
            text: "Esta seguro de realizar un retiro de: "+accounting.formatMoney(vm.retiro.monto,'Bs. ','2','.',','),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Retirar',
            cancelButtonText: 'Cancelar'
          }).then(function(result){
            if (result.value) {
              cashierService.requestWithdrawBalance(0,data)
              .then(function(res){
                vm.send = false;
                vm.bankSelected = undefined;
                vm.retiro = {};
                $window.swal('Exito',res.message,'success')
                $rootScope.$broadcast('updateCajeroUser', res.cajero);
              }, function(err){
                vm.send = false;
                $window.swal('Mensaje',err.data.message,'warning')
                vm.bankSelected = undefined;
                vm.retiro = {};
              })
            }else{
              toastr.error('Accion cancelada');
            }
          })
    }

    vm.search = function(){
      vm.loadData = true;
      cashierService.getTransactionByDate({cajero_web_id: vm.currentUser.cajero.id, date: moment(vm.date).format('YYYY-MM-DD')})
      .then(function(res){
        vm.transactions = undefined;
        vm.transactions = res;
        vm.loadData = false;
      })
    }

    vm.showTransactionDays = function(){
      vm.loadData = false;
      vm.transactions = undefined;
      vm.date = undefined;
      getLastTransaction();
    }



    $rootScope.$on('get_banks_estructura', function(evt, data){
      vm.getBanksStructure();
    })
    $rootScope.$on('updatedUser',function(event,data){
      vm.currentUser = data;
    });
    $rootScope.$on('updateCajeroUser', function(evt, data){
      delete vm.currentUser.cajero
      vm.currentUser.cajero = data;
      $user.setCurrentUser(vm.currentUser);
    })
    $rootScope.$on('updateCupon', function(evt, data){
      vm.coupon = data;
      vm.coupon.disponible = data.monto_cupon - data.monto_usado;
      vm.coupon.pretty_expire = moment.unix(data.expire).format('DD/MM/YYYY');
    })
    $rootScope.$on('upateLastTransaction', function(e, d){
      getLastTransaction();
    })

    $rootScope.$on('updateAmountEffecty', function(evt, data){
      vm.total_effecty = data;
    })

    vm.today = function() {
      vm.date = new Date();
    };
    // vm.today();
  
    vm.clear = function() {
      vm.dt = null;
    };
  
    vm.inlineOptions = {
      customClass: getDayClass,
      minDate: new Date(),
      showWeeks: true
    };
  
    vm.dateOptions = {
      dateDisabled: false,
      formatYear: 'yy',
      // maxDate: new Date(2020, 5, 22),
      minDate: new Date(),
      startingDay: 1
    };
  
    // Disable weekend selection
    function disabled(data) {
      var date = data.date,
        mode = data.mode;
      return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
    }
  
    vm.toggleMin = function() {
      vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
      vm.dateOptions.minDate = vm.inlineOptions.minDate;
    };
  
    vm.toggleMin();
  
    vm.open1 = function() {
      vm.popup1.opened = true;
    };
  
    vm.open2 = function() {
      vm.popup2.opened = true;
    };
  
    vm.setDate = function(year, month, day) {
      vm.dt = new Date(year, month, day);
    };
  
    vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    vm.format = vm.formats[0];
    vm.altInputFormats = ['M!/d!/yyyy'];
  
    vm.popup1 = {
      opened: false
    };
  
    vm.popup2 = {
      opened: false
    };
  
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var afterTomorrow = new Date();
    afterTomorrow.setDate(tomorrow.getDate() + 1);
    vm.events = [
      {
        date: tomorrow,
        status: 'full'
      },
      {
        date: afterTomorrow,
        status: 'partially'
      }
    ];
  
    function getDayClass(data) {
      var date = data.date,
        mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0,0,0,0);
  
        for (var i = 0; i < vm.events.length; i++) {
          var currentDay = new Date(vmscope.events[i].date).setHours(0,0,0,0);
  
          if (dayToCheck === currentDay) {
            return vm.events[i].status;
          }
        }
      }
  
      return '';
    }
  }

})();
// (function () {
//   'use strict';
// angular
//   .module('app')
//   .controller('DetailTransaction', DetailTransaction);

//   DetailTransaction.$inject = ['$sce','$timeout'];

//   function DetailTransaction($sce, $timeout) {
//     var vm = this;

//     console.log('sadasdsad')

//     vm.$onInit = function() {
//       vm.detail_transaction = vm.resolve.detail_transaction;
//       vm.detail_transaction.pretty_ticket = $sce.trustAsHtml(angular.copy(vm.detail_transaction.detalle.replace(/\\n/g, '<br>')));
//     }

//     $timeout(function () {
//       vm.detail_transaction = vm.resolve.detail_transaction;
//       vm.detail_transaction.pretty_ticket = $sce.trustAsHtml(angular.copy(vm.detail_transaction.detalle.replace(/\\n/g, '<br>')));
//       console.log('asdsa')
//     }, 1000)

//   }
// })();