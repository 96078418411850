(function () {
  'use strict';
  angular
  .module('app')
  .component('detailTransaction', {
    templateUrl: 'app/components/lobby-user/detail_transaction.html',
    controller: DetailTransaction,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  DetailTransaction.$inject = ['$sce','$timeout'];

  function DetailTransaction($sce, $timeout) {
    var vm = this;

    console.log('sadasdsad')

    vm.$onInit = function() {
      vm.detail_transaction = vm.resolve.detail_transaction;
      vm.detail_transaction.pretty_ticket = $sce.trustAsHtml(angular.copy(vm.detail_transaction.detalle.replace(/\\n/g, '<br>')));
    }

    $timeout(function () {
      vm.detail_transaction = vm.resolve.detail_transaction;
      vm.detail_transaction.pretty_ticket = $sce.trustAsHtml(angular.copy(vm.detail_transaction.detalle.replace(/\\n/g, '<br>')));
      console.log('asdsa')
    }, 1000)

  }

})();
