(function(){
  'use strict';

  angular
  .module('app')
  .filter('getPrettyDrawZoo', getPrettyDrawZoo);

  function getPrettyDrawZoo(){

    return function(status){
      return {
        "Zoolog-9": 'Zoologico de la suerte 9AM',
        "Zoolog-10": 'Zoologico de la suerte 10AM',
        "Zoolog-11": 'Zoologico de la suerte 11AM',
        "Zoolog-12": 'Zoologico de la suerte 12M',
        "Zoolog-01": 'Zoologico de la suerte 1PM',
        "Zoolog-02": 'Zoologico de la suerte 2PM',
        "Zoolog-04": 'Zoologico de la suerte 4PM',
        "Zoolog-05": 'Zoologico de la suerte 5PM',
        "Zoolog-06": 'Zoologico de la suerte 6PM',
        "Zoolog-07": 'Zoologico de la suerte 7PM'
		  }[status];
    }

  }
})();
