angular
  .module('app')
  .filter('imageUrlSPJ', imageUrlSPJ);

function imageUrlSPJ(BASE_URL) {

  return function (relativePath, defaultPath) {

    // transform the item and return it
    if (relativePath === null) {// cargando o image-null
      return defaultPath || '/images/user2-160x160.png';
    }
    if(relativePath){
      if (relativePath[0] === 'h') {// si comienza por http es absoluta
        return relativePath || (BASE_URL + relativePath);
      }
      return BASE_URL + relativePath;
    }
  };
}
