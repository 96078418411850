angular
.module('app')
.component('modalConfirmPlaysQuintico', {
  templateUrl: 'app/components/modals/modalConfirPlayQuintico.html',
  controller: ModalConfirmPlaysQuinticoController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  }
})

ModalConfirmPlaysQuinticoController.$inject = [];

function ModalConfirmPlaysQuinticoController(){
  var vm = this;

  vm.plays = vm.resolve.plays;
  vm.draws = vm.resolve.draws;
  vm.cancel = cancel;
  vm.deletePlay = deletePlay;
  vm.confirm = confirm;


  function confirm(){
    vm.close({$value: angular.copy(vm.plays)});
  }

  function cancel(){
    vm.dismiss({$value: 'cancel'});
  }

  function deletePlay(index){
    vm.plays.splice(index,1);
  }

}
