angular
  .module('app')
  .component('laPolla', {
    template: '<div ng-include="$ctrl.urlTemplate"></div>',
    controller: LaPollaController,
    bindings: {
      hippodromes: '<'
    }
  });

LaPollaController.$inject = ['toastr', 'moment', 'pollaService', 'user', '$uibModal', '$rootScope', '$sce', '$timeout', 'ActionCableChannel', 'cashierService', '$window', 'miscelaneos'];

function LaPollaController(toastr, moment, $pollaService, $user, $uibModal, $rootScope, $sce, $timeout, ActionCableChannel, $cashierService, $window, $miscelaneos) {
  var vm = this;
  vm.color = '#000';
  vm.countDraw;
  vm.amount = undefined;
  vm.plays = [];
  vm.sale = sale;
  vm.step1 = true;
  vm.step2 = false;
  vm.step2 = false;
  vm.showAnimals = true;
  vm.listCombinationsToPlay = [];
  vm.selectedHippodromes = [];
  vm.nextStep = nextStep;
  vm.clearPlays = clearPlays;
  vm.cancelPlay = cancelPlay;
  vm.aceptPlays = aceptPlays;
  vm.deleteDraw = deleteDraw;
  vm.showTicket = showTicket;
  vm.deleteNumber = deleteNumber;
  vm.makePlays = makePlays;
  vm.producto_id = 5;
  vm.cintillo = undefined;
  vm.date = moment().format('DD/MM/YY');
  var day = moment().format('d');
  var hour = moment().format('H:mm');

  vm.$onInit = function () {
    $pollaService.viewWinnersTable()
    .then(function(res){
      vm.cantViewWinnersTable = res;
      console.log(vm.cantViewWinnersTable)
    })
    // if ($window.localStorage.hasOwnProperty('cintillo')){
    //   vm.cintillo = JSON.parse($window.localStorage.getItem('cintillo'));
    //   $rootScope.$emit('updateCintillo', vm.cintillo);
    //   console.log(vm.cintillo)
    // }else{
    //   vm.cintillo = undefined;
    // }
    vm.stud = undefined;
    vm.hippodromesCopy = angular.copy(vm.hippodromes);
    vm.hippodromes = angular.copy(vm.hippodromes);
    _.each(vm.hippodromes, function(hipo){
      hipo.races = hipo.races.map(function(data,index){
        data.race_title = index + 1;
        data.numberSelected = undefined;
        data.date = moment(data.date).format('HH:mm');
        return data;
      })
      return hipo;
    })
    vm.currentUser = $user.getCurrentUser();
    $miscelaneos.findLasStuds(vm.currentUser.id)
    .then(function(res){
      if(res.length > 0){
        vm.stud = res[0];
      }
    })
    vm.amount = vm.currentUser.super_juegos.la_polla.settings.price_reference;
    
    checkCoupons();
    if (window.mobileAndTabletcheck()) {
      vm.urlTemplate = 'app/components/la_polla/la_polla_mobile.html';
      vm.selectHipo(vm.hippodromes[0])
    } else {
      vm.urlTemplate = 'app/components/la_polla/la_polla.html';
    }

    var channel = new ActionCableChannel("PollaChannel", {});
    var callback = function(message){
      var parseMessage = JSON.parse(message);
      var hippodrome_id = parseMessage.hippodrome_id;
      var new_hippodrome = parseMessage.hippodrome[0];
      var copyCurrentUser = angular.copy(vm.currentUser);
      var hippodromes = _.reject(vm.currentUser.super_juegos.la_polla.hippodromes, function(hippodrome){ return hippodrome.id == hippodrome_id });
      hippodromes.push(new_hippodrome)
      copyCurrentUser.super_juegos.la_polla.hippodromes = [];
      copyCurrentUser.super_juegos.la_polla.hippodromes = hippodromes;
      
      $user.setCurrentUser(copyCurrentUser);
      toastr.info('Actualizando informacion de carreras', {
        timeOut: 2000,
        progressBar: true
      });

      $timeout(function(){
        $window.location.reload();
      }, 2000)
    };

    channel.subscribe(callback)
    .then(function(){
      console.log('Conectado al Canal')
      vm.sendToMyChannel = function(message){
        channel.send(message);
      };
    });
  }


  function sale() {
    vm.sendData = true;
    var plays = _.reduce(vm.listCombinationsToPlay, function(memo, data, index){
      data.index = index + 1;
      memo.push(data)
      return memo;
    }, [])

    var data = {
      fec: moment().format('DD/MM/YY'),
      ced: vm.currentUser.rif,
      nom: vm.currentUser.nombre,
      correo: vm.currentUser.correo,
      jug: plays,
      compress: false,
      cod: vm.currentUser.id,
      producto_id: 9,
      cda: true,
      stud: vm.stud,
    }


    if (vm.cupon != null && vm.total.monto > vm.cupon.disponible) {
      $window.swal({
        html: 'Modifique la juagada si desea usar el bono exacto. <br>' +
          '<strong>Monto disponible:</strong>' + vm.cupon.disponible + '<br>',
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
        allowOutsideClick: false,
      }).then(function (result) {
        if (result.value) {
          $pollaService.sale(data)
            .then(function (res) {
              vm.sendData = false;
              completeSale(res);
            }, function (err) {
              vm.sendData = false;
              $window.swal('Aviso', err.data.message, 'warning');
            })
        } else {
          vm.sendData = false;
          return;
        }
      })
    } else {
      $pollaService.sale(data)
        .then(function (res) {
          vm.sendData = false;
          completeSale(res);
        }, function (err) {
          vm.sendData = false
          $window.swal('Aviso', err.data.message, 'warning');
        })
    }
  }

  function completeSale(res) {
    switch (res.cmd) {
      case "C11":
        var modal = $uibModal.open({
          animation: true,
          component: 'modalConfirmLoteries',
          backdrop: 'static',
          windowClass: 'modalConfirmLoteries',
          size: 'md',
          resolve: {
            plays: function () {
              return angular.copy(vm.plays);
            },
            playsDenied: function () {
              return res;
            }
          },
        });

        modal.result.then(function (res) {
          vm.aceptPlays(res);
          vm.clearPlays();
        }, function (err) {
          vm.cancelPlay(res);
          vm.clearPlays();
        })
        break;
      case "C12":
        toastr.error('La carrera seleccionada ya se encuentra cerrada', 'Error');
        vm.clearPlays();
        break;
      case "C10":
        vm.clearPlays();
        $rootScope.$broadcast('updateCajeroUser', res.cajero_web);
        if (res.cupon != null) {
          $rootScope.$broadcast('updateCupon', res.cupon);
        }
        vm.ticket = $sce.trustAsHtml(angular.copy(res.ticket_string.replace(/\n|\r|\\n/g, '<br>')));
        vm.ticket_print = angular.copy(res.ticket_string.replace(/\n|\r/g, '\\n'));
        vm.ticket_ws = angular.copy(res.ticket_string.replace(/\n|\r/g, '<br>'));

        $timeout(function () {
          vm.showTicket(vm.ticket, vm.ticket_print, vm.ticket_ws)
        }, 0)
        if (window.mobileAndTabletcheck()) {
          vm.step1 = true;
          vm.step2 = false;
        }
        _.forEach(vm.signs, function (data) {
          data.checked = false;
        });
        break;
      default:

    }
  }

  function showTicket(ticket, ticket_print, ticket_ws) {
    var modal = $uibModal.open({
      animation: true,
      component: 'gModalTicket',
      backdrop: 'static',
      windowClass: 'modalTicket',
      size: 'xs',
      resolve: {
        ticket: function () {
          return ticket;
        },
        ticket_print: function () {
          return ticket_print;
        },
        ticket_ws: function () {
          return ticket_ws;
        }
      },
    })
    modal.result.then(function (res) {
      vm.clearPlays();
    })
  }

  function aceptPlays(data) {
    vm.clearPlays();
    var data = {
      cod: vm.currentUser.id,
      ven: 1,
      ticket: data.nt,
      jp: "H",
      producto_id: 5
    }
    $pollaService.confirmSale(data)
      .then(function (res) {
        completeSale(res);
        if (window.mobileAndTabletcheck()) {
          vm.step1 = true;
          vm.step2 = false;
        }
      })
  }

  function cancelPlay(data) {
    var data = {
      cod: vm.currentUser.id,
      ven: 1,
      ticket: data.nt,
      jp: "H"
    }
    $pollaService.reverTicket(data)
      .then(function (res) {
        // toastr.info('Jugada Devuelta');
        if (window.mobileAndTabletcheck()) {
          vm.step1 = true;
          vm.step2 = false;
        }
      })
  }

  function getNumeros(draw, plays) {
    var numeros = _.reduce(vm.signs, function (memo, data) {
      if (data.checked) {
        _.forEach(plays, function (play) {
          memo.push({
            n: play,
            s: data.code,
            c: draw.id,
            m: vm.amount
          })
        })
      }
      return memo;
    }, []);
    return numeros;
  }

  function deleteNumber(draw, index) {
    draw.numeros.splice(index, 1);
    if (draw.numeros.length == 0) {
      vm.listCombinationsToPlay.splice(draw, 1);
    }
    calculateTotal();
  }

  function deleteDraw(draw) {
    vm.listCombinationsToPlay.splice(draw, 1);
    calculateTotal();
  }

  function checkPlays() {
    var next = 0;
    _.forEach(vm.hippodromes, function(hipo){
      if(hipo.selected){
        if(hipo.races.length == _.pluck(hipo.races, 'numberSelected').length){
          next++
        }
      }
    })

    if(next == _.where(vm.hippodromes, {selected: true}).length){
      return true;
    }else{
      return false;
    }
  }

  function clearPlays() {
    checkCoupons();
    calculateTotal();
    vm.listCombinationsToPlay = [];
    if ($window.mobileAndTabletcheck()) {
      vm.step1 = true;
      vm.step2 = false;
    }
    vm.$onInit();
  }

  function nextStep() {
    
    if(valid()){
      if (vm.step1) {
        if(vm.stud){
          vm.step1 = false;
          vm.makePlays();
          $timeout(function () {
            vm.step2 = true;
          }, 0)
          return
        }else{
          $window.swal('info','Debe indicar un stud','info')
          return;
        }
      }
    }else{
      $window.swal('info','Debe completar su jugada','info')
    }
  }

  vm.continuePlaying = function(){
    if (vm.step2) {
      vm.step1 = true;
      vm.$onInit();
      $timeout(function () {
        vm.step2 = false;
      }, 0)
      return
    }
  }

  function valid(){
    var valid = true;
    _.forEach(vm.hippodromes, function(hipo){
      if(hipo.selected){
        _.forEach(hipo.races, function(race){
          if(!race.numberSelected){
            valid = false;
          }
        })
      }
    })
    return valid; 
  }


  //Custom plays

  function checkCoupons() {
    $cashierService.getCoupon()
      .then(function (res) {
        vm.cupon = res;
        // if (checkLoteriesDraws()) {
        //   // if(parseFloat(vm.currentUser.cajero.saldo_actual) == 0 && vm.cupon == null){
        //   //   vm.notBalance = true;
        //   //   vm.errorMessage = 'Recarge su saldo para continuar jugando';
        //   // }
        // } else {
        //   // vm.notBalance = true;
        //   // vm.errorMessage = 'Taquilla cerrada';
        // }
      })
  }

  
  function makePlays() {
    if(vm.stud){
      if (checkPlays()) {
        if (window.mobileAndTabletcheck()) {
          var listCombinationsToPlay = _.reduce(vm.hippodromes, function(memo, data){
            if(data.races[0].numberSelected){
              memo.push({
                title: data.name,
                h: data.id,
                j: getPlays(data.races)
              })
              
            }
            return memo;
          },[])
          console.log(vm.listCombinationsToPlay)
          vm.listCombinationsToPlay = vm.listCombinationsToPlay.concat(listCombinationsToPlay)
        }else{
          var listCombinationsToPlay = _.reduce(vm.hippodromes, function(memo, data){
            if(data.selected){
              memo.push({
                title: data.name,
                h: data.id,
                j: getPlays(data.races)
              })
              
            }
            return memo;
          },[])
          vm.listCombinationsToPlay = vm.listCombinationsToPlay.concat(listCombinationsToPlay)
        }
        calculateTotal();
      } else {
        toastr.warning('Ingrese una jugada.', 'Por favor espere!');
      }
    }else{
      $window.swal('¡Stop!','Debe Indicar un STUD para continuar','info');
      return;
    }
  }

  function getPlays(races){
    return _.reduce(races, function(memo, race){
        memo.push({
          e: race.numberSelected,
          s: null,
          r: race.id,
          m: vm.amount,
          c_title: race.number,
          r_title: race.race_title
        })
        return memo;
    },[])
  }

  vm.selectHipo = function(hipo){
    _.forEach(vm.hippodromes, function(h){
      h.selected = false;
    })

    vm.currentHippodrome = hipo
    vm.currentHippodrome.selected = !hipo.selected
  }

  vm.selectRider = function(race, rider){
    if(rider.favorite){
      swal('¡Stop!','Caballo Invalidado','info');
      return;
    }
    race.numberSelected = rider.number;
  }

  function calculateTotal() {
    vm.total = { monto: vm.amount * vm.listCombinationsToPlay.length, jugadas: vm.listCombinationsToPlay.length };
  }

}
