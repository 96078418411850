angular
.module('app')
.component('rifcar', {
  template: '<div ng-include="$ctrl.urlTemplate"></div>',
  controller: rifaController,
  bindings: {
    rifas: '<'
  }
});

rifaController.$inject = ['toastr', 'moment', 'user','$uibModal','swal','$rootScope','$sce','$timeout','miscelaneos','rifcarServices','cashierService','$window'];

function rifaController(toastr, moment, $user, $uibModal, swal, $rootScope,$sce,$timeout, $miscelaneos, $rifcarServices, $cashierService, $window) {
  var vm = this;
  vm.color = 'rgb(19 38 85 / 1)';
  vm.countDraw;
  vm.amount = 0;
  vm.plays = [];
  vm.sale = sale;
  vm.step1 = true;
  vm.step2 = false;
  vm.step3 = false;
  vm.listCombinationsToPlay = [];
  vm.setAside = setAside;
  vm.nextStep = nextStep;
  vm.clearDraws = clearDraws;
  vm.clearPlays = clearPlays;
  vm.deleteRifa = deleteRifa;
  vm.showTicket = showTicket;
  vm.deleteNumero = deleteNumero;
  vm.showRafflePreview = showRafflePreview;
  vm.checkRifaSelected = checkRifaSelected;
  vm.backToFirst = backToFirst;
  vm.requestRandomCombinations = requestRandomCombinations;
  vm.allsigns = allsigns;
  vm.producto_id = $miscelaneos.getProductIdByGame('Rifcar');
  vm.date = moment().format('DD/MM/YY');
  var day = moment().format('d');
  var hour = moment().format('H:mm');


  vm.$onInit = function(){
    vm.currentUser = $user.getCurrentUser();
    checkCoupons();
    if(window.mobileAndTabletcheck()){
      vm.urlTemplate = 'app/components/rifcar/rifcar-mobile.html';
    }else{
      vm.urlTemplate = 'app/components/rifcar/rifcar.html';
    }
    vm.signs = $miscelaneos.getSigns();

  }

  function setAside(combination){
    var combinacion = parseFloat(combination) > 0 ? combination : vm.combination

    if(combinacion != undefined){
      if(checkRifaSelected()){
        if(vm.listCombinationsToPlay.length <= 0){
          var rifasSelected = _.reduce(vm.rifas, function(memo, rifa){
            if(rifa.checked){
              var jugadas = {numeros: [], rifa: null, rifa_id: null};
              jugadas.rifa = rifa.rifade;
              jugadas.rifa_id = rifa.id;
              jugadas.numeros = jugadas.numeros.concat(getNumeros(rifa))
              memo.push(jugadas);
            }
            return memo;
          },[])
          vm.listCombinationsToPlay = vm.listCombinationsToPlay.concat(rifasSelected)
        }else{
          _.forEach(vm.rifas, function(rifa){
            if(rifa.checked){
              var rifaExist = _.findWhere(vm.listCombinationsToPlay, {rifa_id: rifa.id})
              if(rifaExist){
                _.forEach(vm.signs, function(signo){
                  if(signo.checked){
                    var numeroExist = _.findWhere(rifaExist.numeros, {n: vm.combination, s: signo.code});
                    if(!numeroExist){
                      rifaExist.numeros.push({
                        n: vm.combination,
                        s: signo.code,
                        c: rifa.id.toString(),
                        m: rifa.valor
                      })
                    }
                  }
                })
              }else{
                var jugadas = {numeros: [], rifa: null, rifa_id: null};
                jugadas.rifa = rifa.rifade;
                jugadas.rifa_id = rifa.id;
                jugadas.numeros = jugadas.numeros.concat(getNumeros(rifa))
                vm.listCombinationsToPlay.push(jugadas);
              }
            }
          })
        }
      }else{
        toastr.warning('Seleccione una rifa');
      }
    }
    calculateTotal();
    if(window.mobileAndTabletcheck()){
      vm.step2 = false;
      $timeout(function(){
        vm.step3 = true;
      },1)
    }
    clearPlays();
  }

  function sale(){
    var i = 1;
    vm.selling = true;

    vm.plays = angular.copy(
      _.reduce(vm.listCombinationsToPlay, function(memo, data){
        memo.push({
          c: data.rifa_id.toString(),
          j: data.numeros
        })
        return memo;
      },[])
    )

    _.forEach(vm.plays, function(draw){
      _.forEach(draw.j, function(play){
        play.i = i;
        i++;
      })
    })

    var data = {
      cod: vm.currentUser.id,
      ven: 1,
      fec: moment().format('DD/MM/YY'),
      ced: vm.currentUser.rif,
      nom: vm.currentUser.nombre,
      ts: 1,
      correo: vm.currentUser.correo,
      jug: vm.plays,
      ncanje: 0,
      mcanje: 0,
      cupon: "",
      usa_cupon: false,
      app: 3,
      producto_id: vm.producto_id,
      beneficiencia: 'LOTERIA DE COJEDES',
      cda: true,
    }

    if(vm.cupon != null && vm.totalAmount > vm.cupon.disponible){
      $window.swal({
        html: 'Modifique la juagada si desea usar el bono exacto. <br>'+
        '<strong>Monto disponible:</strong>'+vm.cupon.disponible +'<br>',
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
        allowOutsideClick: false,
      }).then(function(result){
        if (result.value) {
          $rifcarServices.buyCombinations(data)
          .then(function(res){
            vm.selling = false;
            completeSale(res);
          }, function(err){
            vm.selling = false;
            $window.swal('Aviso',err.data.message,'warning');
            // vm.clearPlays();
          })
        }else{
          vm.selling = false;
          return;
        }
      })
    }else{
      $rifcarServices.buyCombinations(data)
      .then(function(res){
        vm.selling = false;
        completeSale(res);
      }, function(err){
        vm.selling = false;
        $window.swal('Aviso',err.data.message,'warning');
        // vm.clearPlays();
      })
    }
  }

  function completeSale(res) {
    switch (res.cmd) {
      case "C11":
      showModalCombinations(res)
      break;
      case "C12":
        $window.swal('Aviso','Las Jugadas Seleccionadas no tienen ningun limite','warning');
        vm.clearDraws();
        vm.clearPlays();
      break;
      case "C10":
        vm.clearPlays();
        vm.clearDraws();
        $rootScope.$broadcast('updateCajeroUser',res.cajero_web);
        // if(parseFloat(res.cajero_web.saldo_actual) < parseFloat(vm.draws[0].amount)){
        //   vm.notBalance = true;
        // }
        // vm.ticket = $sce.trustAsHtml(angular.copy(res.ticket.replace(/\n|\r/g, '<br>')));
        // $timeout(function(){
        //   vm.showTicket(vm.ticket)
        // },0)
        // vm.ticket = $sce.trustAsHtml(angular.copy(res.ticket.replace(/\n|\r/g, '<br>')));
        vm.ticket = $sce.trustAsHtml(angular.copy(res.ticket.replace(/\\n/g, '<br>')));
        // vm.ticket_print = angular.copy(res.ticket.replace(/\n|\r/g, '\n'));
        vm.ticket_ws = angular.copy(res.ticket.replace(/\\n/g, '<br>'));
        vm.ticket_print = res.ticket;
        $timeout(function(){
          vm.showTicket(vm.ticket, vm.ticket_print, vm.ticket_ws)
        },0)
        vm.listCombinationsToPlay = [];
        vm.plays = [];
        if(window.mobileAndTabletcheck()){
          vm.step1 = true;
          vm.step3 = false;
        }
      break;
      default:

    }
  }

  function showModalCombinations(response){

    var modal = $uibModal.open({
      animation: true,
      component: 'modalCombiationsRifcar',
      backdrop: 'static',
      windowClass: 'modalCombinations',
      size: 'xs',
      resolve: {
        response: function() {
          return response;
        },
        plays: function(){
          return vm.plays
        },
        producto_id: function(){
          return vm.producto_id;
        }
      },
    });
    modal.result.then(function(res){
      completeSale(res)
    }, function(res2){
      toastr.info('Compra Cancelada')
      completeSale({cmd: 'C16'})
    })
  }

  function showTicket(ticket, ticket_print,ticket_ws){
    var modal = $uibModal.open({
      animation: true,
      component: 'gModalTicket',
      backdrop: 'static',
      windowClass: 'modalTicket',
      size: 'xs',
      resolve: {
        ticket: function() {
          return ticket;
        },
        ticket_print: function(){
          return ticket_print;
        },
        ticket_ws: function(){
          return ticket_ws;
        }
      },
    });
  }

  function getNumeros(rifa){
    var numeros = _.reduce(vm.signs, function(memo, data){
      if(data.checked){
        memo.push({
          i: null,
          n: vm.combination,
          s: data.code,
          c: rifa.id.toString(),
          m: rifa.valor
        })
      }
      return memo;
    },[]);
    return numeros;
  }

  function checkRifaSelected(){
    var i = 0;
    _.forEach(vm.rifas, function(draw){
      if(draw.checked){
        i++
      };
    });

    return i > 0 ? true : false;

  }

  function deleteNumero(rifa, index){
    var item = _.findWhere(vm.listCombinationsToPlay, {rifa_id: rifa.rifa_id});
    item.numeros.splice(index, 1)

    if(rifa.numeros.length == 0){
      vm.listCombinationsToPlay.splice(_.indexOf(vm.listCombinationsToPlay, rifa), 1)
    }
    calculateTotal();
  }

  function deleteRifa(index){
    vm.listCombinationsToPlay.splice(index, 1);
    calculateTotal();
  }

  function clearPlays(){
    _.forEach(vm.rifas.length, function(draw){
      draw.checked = false;
    })
    _.forEach(vm.signs, function(signo){
      signo.checked = false;
    })
    vm.combination = undefined;
    calculateTotal();
  }

  function clearDraws(){
    _.forEach(vm.drawsZoo, function(res){
      res.checked = false;
    })
  }

  function nextStep(){
    if(vm.step1){
      if(checkRifaSelected()){
        vm.step1 = false;
        $timeout(function(){
          vm.step2 = true;
        },0)
        return
      }else{
        toastr.info('Seleccine una rifa');
      }
    }

    if(vm.step2){
      vm.step2 = false;
      $timeout(function(){
        vm.step3 = true;
      },0)
      return;
    }
  }

  function calculateTotal(){
    vm.totalAmount = _.reduce(vm.listCombinationsToPlay, function(memo, data){
      memo += data.numeros.length * parseFloat(data.numeros[0].m);
      return memo;
    },0);
  }



  function checkDraws(){
    if(vm.drawsZoo.length > 0){
      $interval(function(){
        var currentHora = parseInt(moment().format('Hmm'))
        _.forEach(vm.drawsZoo, function(data, index){
          var horaSorteo = parseInt(data.horac.split(':').join(''))
          if(horaSorteo < currentHora){
            vm.drawsZoo.splice(index, 1);
          }
        })
      },1000)
    }
  }

  function allsigns(){
    _.forEach(vm.signs, function(signo){
      signo.checked = true;
    })

    setAside(vm.combination);
  }

  function showRafflePreview(rifa){
    vm.currenRaffle = rifa;
  }

  function backToFirst(){
    vm.step3 = false;
    $timeout(function(){
      vm.step1 = true;
    },0)

  }

  function requestRandomCombinations(){
    var data = {
      cod: vm.currentUser.id,
      ven: 1,
      cupon: "",
      usa_cupon: false,
      app: 3,
      cb: vm.cntTickets
    }

    $rifcarServices.getRandCombinations(data)
    .then(function(res){
      var numeros = _.reduce(res.numeros, function(memo, data){
        memo.push({
          i: null,
          n: data.numero,
          s: data.signo,
          c: data.c,
          m: _.findWhere(vm.rifas.length, {id: data.c}).valor
        })
        return memo;
      },[]);
      var rifasGroup = _.groupBy(numeros, function(data){
        return data.c;
      })

      if(vm.listCombinationsToPlay.length <= 0){
        var rifasSelected = _.reduce(vm.rifas.length, function(memo, rifa){
          var rifaExist = _.findWhere(res.numeros, {c: rifa.id})
          if(rifaExist){
            var jugadas = {numeros: [], rifa: null, rifa_id: null};
            jugadas.rifa = rifa.rifade;
            jugadas.rifa_id = rifa.id;
            jugadas.numeros = rifasGroup[rifa.id];
            memo.push(jugadas);
          }
          return memo;
        },[])
        vm.listCombinationsToPlay = vm.listCombinationsToPlay.concat(rifasSelected);

      }else{
        _.forEach(numeros, function(rifa){
          var rifaExist = _.findWhere(vm.listCombinationsToPlay, {rifa_id: rifa.c})
          if(rifaExist){
            rifaExist.numeros.push(rifa);
          }
        })

      }
      calculateTotal();
    })
  }

  function checkCoupons(){
    $cashierService.getCoupon()
    .then(function(res){
      vm.cupon = res;
      if(vm.rifas.length == 0){
        vm.notBalance = true;
        vm.errorMessage = 'Taquilla cerrada';
      }else{
        // if(parseFloat(vm.currentUser.cajero.saldo_actual) == 0 && vm.cupon == null){
        //   vm.notBalance = true;
        //   vm.errorMessage = 'Recarge su saldo para continuar jugando';
        // }
      }
    })
  }




}
