(function () {
  'use strict';
  angular
  .module('app')
  .component('pointSales', {
    template: '<div ng-include="$ctrl.urlTemplate"></div>',
    controller: PointSalesController,
  });

  PointSalesController.$inject = ['$window', 'user','toastr','agentsPanelService','$rootScope','$stateParams','$state'];

  function PointSalesController($window, $user, toastr, $agentsPanelService, $rootScope, $stateParams, $state) {
    var vm = this;
    vm.currentUser = $user.getCurrentUser();
    vm.savePointSales = savePointSales;
    vm.viewPromotionalCode = false;
    vm.showPrintCode = showPrintCode;
    vm.print = print;
    vm.modifyPointSale = modifyPointSale;
    vm.point_sale = {es_credito: false};

    vm.$onInit = function(){
      if($stateParams.action == 'crear-centro-de-apuestas'){
        vm.urlTemplate = 'app/components/point-sales/crear-point-sales.html';
        if($stateParams.point_sales){
          $agentsPanelService.getMyPointSale($stateParams.point_sales)
          .then(function(res){
            vm.point_sale = res;
            vm.point_sale.username = res.username;
            vm.loading = false;
            $agentsPanelService.getStructurePercentsBonus($stateParams.point_sales)
            .then(function(res){
              vm.percentsBonus = _.map(res, function(data){
                data.referencial = parseInt(data.comision);
                return data;
              })
            })
          })
        }else{
          $agentsPanelService.getMyPercentsBonus()
          .then(function(res){
            vm.percentsBonus = _.map(res, function(data){
              data.referencial = parseInt(data.comision);
              return data;
            })
          })
        }
      }else{
        vm.urlTemplate = 'app/components/point-sales/list-point-sales.html';
      }

      $agentsPanelService.getMyPointSales()
      .then(function(res){
        vm.point_sales = res;
        vm.loading = false;
      })
    }

    function savePointSales(){

      if(!vm.point_sale.id){
        _.forEach(vm.percentsBonus, function(data){
          if(parseInt(data.referencial) == parseInt(data.comision)){
            toastr.error('El bono para '+data.producto+' no puede ser igual al monto'+data.comision);
          }
        })
      }
      var configuracion_estructura;
      configuracion_estructura = _.reduce(vm.percentsBonus, function(memo, data){
        memo.push({
          comision: data.referencial,
          loteria_id: data.loteria_id,
          participacion: data.participacion,
          id: data.id ? data.id : null
        })
        return memo;
      },[])
      var data = {
        nombre: vm.point_sale.id ? vm.point_sale.nombre : '_cda_'+vm.point_sale.nombre,
        representante: vm.point_sale.representante,
        telefono: vm.point_sale.telefono,
        direccion:vm.point_sale.direccion,
        correo: vm.point_sale.username,
        rif: vm.point_sale.rif,
        activo: true,
        username: vm.point_sale.username,
        password: vm.point_sale.password,
        password_confirmation:vm.point_sale.password_confirmation,
        configuracion_estructura: configuracion_estructura,
        es_credito: vm.point_sale.es_credito,
        referencia_credito: vm.point_sale.es_credito ? vm.point_sale.credito : null,
        efectivo: vm.point_sale.efectivo ? vm.point_sale.efectivo : null
      }

      if(vm.point_sale.id){
        data.id = vm.point_sale.id
        $agentsPanelService.updatePointSale(data)
        .then(function(res){
          vm.point_sale = undefined;
          toastr.success(res.message);
          $state.go('agentspanel');
        })
      }else{
        $agentsPanelService.createPointSale(data)
        .then(function(res){
          vm.point_sale = undefined;
          toastr.success(res.message);
          $state.go('agentspanel');
        })
      }

      
    }

    function showPrintCode(data){
      vm.viewPromotionalCode = true;
      vm.currentPointSale = data;
      vm.currentPointSale.promotional_code_url = 'http://www.realparley.com/register/'+data.promotional_code
    }
    function modifyPointSale(data){
      vm.currentPointSale = data;
      // vm.viewPromotionalCode = true;
      // vm.currentPointSale.promotional_code_url = 'http://www.realparley.com/register/'+data.promotional_code
    }

    function print(){
      $window.print();
    }



  }

})();
