(function(){
  'use strict';

  angular
  .module('app')
  .filter('prettySign', prettySign);

  function prettySign(){

    return function(combination){
      return {
        '1':'Aries',
        '2':'Tauro',
        '3':'Geminis',
        '4':'Cancer',
        '5':'Leo',
        '6':'Virgo',
        '7':'Libra',
        '8':'Escorpio',
        '9':'Sagitario',
        '10':'Capricornio',
        '11':'Acuario',
        '12':'Piscis'
      }[combination];
    }

  }
})();
