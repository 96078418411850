(function(){
    'use strict';
  
    angular
    .module('app')
    .component('modalPromo', {
      templateUrl: 'app/components/promos/promo.html',
      controller: PromoModalController,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
      }
    })
  
    PromoModalController.$inject = ['user', 'toastr', 'moment','$window','$rootScope','authService'];
  
    function PromoModalController($user, toastr, moment, $window, $rootScope, $authService) {
      var vm = this;
  
      vm.$onInit = function(){
        vm.promo = vm.resolve.promo;
      }
    }
  })();
  