(function () {
  'use strict';
  angular
  .module('app')
  .component('authAgentes', {
    templateUrl: 'app/components/auth-agentes/auth-agentes.html',
    controller: AuthAgentesController
  });

  AuthAgentesController.$inject = ['authService', 'user', '$window','$rootScope','$state'];

  function AuthAgentesController($authService, $user, $window, $rootScope, $state) {
    var vm = this;
    vm.send = false;
    vm.doLogin = doLogin;
    vm.user = {email: '', password: ''};

    function doLogin() {

      vm.send = true;
      $authService.doLogin(vm.user)
      .then(function (res) {
        vm.send = false;
        vm.user = res.estructura;
        $user.setCurrentUser(vm.user);
        $rootScope.$broadcast('updatedUser', vm.user);
        $window.location = '/panel-de-agentes';
      }, function (err) {
        vm.send = false;
        $window.swal('Error!',err.data.message,'warning');
      });
    }
  }

})();
