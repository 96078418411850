(function() {
  'use strict';
  angular
    .module('app')
    .factory('cashierService', cashierService);

  cashierService.$inject = ['$http', 'api'];

  function cashierService($http, api) {

    return {
      getLastTransactions: getLastTransactions,
      requestWithdrawBalance: requestWithdrawBalance,
      requestBalance: requestBalance,
      getCajerWeb: getCajerWeb,
      getCoupon: getCoupon,
      getHistory: getHistory,
      activeCoupon: activeCoupon,
      getVipRequest: getVipRequest,
      updateVipRequest: updateVipRequest,
      getTransaction: getTransaction,
      getTransactionByDate: getTransactionByDate,
      getPendingCash: getPendingCash
    };
   
    function getLastTransactions(cajeroWebid) {
      return $http.get(api.getApiSPJ('cajero_web/' + cajeroWebid + '/cajero_web_operacion'))
      .then(function(res){
        return res.data;
      })
    }
    function getTransaction(cajero_web_id) {
      return $http.get(api.getApiSPJ('cajero_web_operacion/'+cajero_web_id))
      .then(function(res){
        return res.data;
      })
    }
    function getHistory(cajero_web_id) {
      return $http.get(api.getApiSPJ('cajero_web/'+cajero_web_id+'/cajero_web_operacion/get_history_movements'))
      .then(function(res){
        return res.data;
      })
    }
    function getTransactionByDate(data) {
      return $http.get(api.getApiSPJ('cajero_web/'+data.cajero_web_id+'/cajero_web_operacion/get_history_movements_date?date='+data.date))
      .then(function(res){
        return res.data;
      })
    }

    function requestWithdrawBalance(id, data){
      return $http.post(api.getApiSPJ('solicitud_operacion/'+id+'/withdraw_balance'), data)
      .then(function(res){
        return res.data;
      })
    }

    function requestBalance(data){
      return $http.post(api.getApiSPJ('solicitud_operacion'), data)
      .then(function(res){
        return res.data;
      })
    }

    function getCajerWeb(id){
      return $http.get(api.getApiSPJ('cajero_web/'+id))
      .then(function(res){
        return res.data;
      })
    }

    function getCoupon(){
      return $http.get(api.getApiSPJ('cajero_web/get_coupon'))
      .then(function(res){
        return res.data;
      })
    }

    function activeCoupon(data){
      return $http.post(api.getApiSPJ('cajero_web/active_coupon'), data)
      .then(function(res){
        return res.data;
      })
    }
    function getVipRequest(){
      return $http.get(api.getApiSPJ('solicitud_operacion/get_vip_request'))
      .then(function(res){
        return res.data;
      })
    }

    function updateVipRequest(data){
      return $http.post(api.getApiSPJ('solicitud_operacion/update_vip_request'), data)
      .then(function(res){
        return res.data;
      })
    }

    function getPendingCash(){
      return $http.get(api.getApiSPJ('solicitud_operacion/pending_cash'))
      .then(function(res){
        return res.data;
      })
    }

   

  }
})();
