angular
.module('app')
.component('singup', {
  template: '<div ng-include="$ctrl.urlTemplate"></div>',
  controller: SingupController
});

SingupController.$inject = ['$log','SingUpService','$window','$state','$rootScope','$stateParams'];

function SingupController($log, $SingUpService, $window, $state, $rootScope, $stateParams) {
  var vm = this;
  vm.send = false;
  vm.user = {};
  vm.states = ['Amazonas', 'Anzoátegui', 'Apure', 'Aragua', 'Barinas', 'Bolívar', 'Carabobo', 'Cojedes', 'Delta Amacuro', 'Falcón', 'Guárico', 'Lara', 'Mérida', 'Miranda', 'Monagas', 'Nueva Esparta', 'Portuguesa', 'Sucre', 'Táchira', 'Trujillo', 'Vargas', 'Yaracuy', 'Zulia', 'Distrito Capital'];
  vm.response = null;
  vm.widgetId = null;
  vm.noCapchat = false;
  vm.model = {key: '6Ldd9JkUAAAAALxjgqAT4sPA2L5S_NU5e65O3Vi_'};
  vm.setResponse = setResponse;
  vm.setWidgetId = setWidgetId;
  vm.save = save;
  // vm.saveAgent = saveAgent;
  vm.cancel = cancel;


  vm.$onInit = function(){
    if($stateParams.promotional_code){
      vm.user.agent_code = $stateParams.promotional_code;
    }

    if($stateParams.tipo_de_registro == 'agentes'){
      vm.registroAgente = true;
      vm.urlTemplate = 'app/components/singup/singup-agent.html';
    }
    if($stateParams.tipo_de_registro == 'jugadores'){
      vm.registroAgente = false;
      vm.urlTemplate = 'app/components/singup/singup.html';
    }

  }

  function setResponse(response) {
    vm.response = response;
    if (vm.valid) {
      vm.noCapchat = false;
    }
  }

  function setWidgetId(widgetId) {
    vm.widgetId = widgetId;
  }
  
  function save(){
    vm.send = true;

    if(vm.isAdult >= 18){
      var customer = {
        nombre: vm.user.first_name +' '+ vm.user.last_name,
        telefono: vm.user.number_phone,
        direccion:vm.user.state,
        correo: vm.user.email,
        birthdate: vm.user.birthdate,
        tipo_estructura_id: vm.registroAgente ? 2 : 3,
        rif: vm.user.cedula,
        activo: true,
        username: vm.user.email,
        password: vm.user.password,
        password_confirmation:vm.user.password_confirmation,
        promotional_code: vm.user.agent_code,
        padre_id: vm.user.agent_code ? null : 78
      }

      if(vm.registroAgente){
        customer.representante = vm.user.first_name +' '+ vm.user.last_name;
        customer.nombre = vm.user.nombre;
        $SingUpService.registerAgent(customer)
        .then(function(res){
          vm.send = false;
          $window.swal('Agente registrado exitosamente',res.message, 'success');
          vm.user = {};
          setTimeout(function(){
            $state.go('home');
          },3000)
        }, function(err){
          vm.send = false;
          $window.swal('Error!',err.data.message,'error');
        })
      }else{
        $SingUpService.registerCustomer(customer)
        .then(function(res){
          vm.send = false;
          $window.swal('Usuario registrado exitosamente',res.message, 'success');
          vm.user = {};
          setTimeout(function(){
            $state.go('tutoDeposit');
          },1000)
        }, function(err){
          vm.send = false;
          $window.swal('Error!',err.data.message,'error');
        })
      }
    }else{
      $window.swal('Alto!','Esta prohibido el registro de menores de edad','warning')
      vm.send = false;
    }
  }


  vm.validateAge = function(){
    vm.isAdult = moment().diff(vm.user.birthdate, 'years');
  }

  function cancel() {
    $state.go('home');
  }

  vm.today = function() {
    vm.dt = new Date();
  };
  vm.today();

  vm.clear = function() {
    vm.dt = null;
  };

  vm.inlineOptions = {
    customClass: getDayClass,
    minDate: new Date(),
    showWeeks: false
  };

  vm.dateOptions = {
    dateDisabled: false,
    formatYear: 'yy',
    maxDate: new Date(2020, 5, 22),
    minDate: new Date(),
    startingDay: 1
  };

  vm.toggleMin = function() {
    vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
    vm.dateOptions.minDate = vm.inlineOptions.minDate;
  };
  vm.toggleMin();

  vm.open1 = function() {
    vm.popup1.opened = true;
  };

  vm.open2 = function() {
    vm.popup2.opened = true;
  };

  vm.setDate = function(year, month, day) {
    vm.dt = new Date(year, month, day);
  };

  vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
  vm.format = vm.formats[0];
  vm.altInputFormats = ['M!/d!/yyyy'];

  vm.popup1 = {
    opened: false
  };

  vm.popup2 = {
    opened: false
  };

  var tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  var afterTomorrow = new Date();
  afterTomorrow.setDate(tomorrow.getDate() + 1);
  vm.events = [
    {
      date: tomorrow,
      status: 'full'
    },
    {
      date: afterTomorrow,
      status: 'partially'
    }
  ];

  function getDayClass(data) {
    var date = data.date,
      mode = data.mode;
    if (mode === 'day') {
      var dayToCheck = new Date(date).setHours(0,0,0,0);

      for (var i = 0; i < vm.events.length; i++) {
        var currentDay = new Date(vm.events[i].date).setHours(0,0,0,0);

        if (dayToCheck === currentDay) {
          return vm.events[i].status;
        }
      }
    }

    return '';
  }


}
