(function(){
  'use stric';

  angular
  .module('app')
  .component('gamesPublicity', {
    templateUrl: 'app/components/games-publicity/games-publicity.html',
    controller: GamesPublicityController
  })

  function GamesPublicityController($state){
    var vm = this;
    vm.currentState = $state.current.name;

    vm.$onInit = function(){
      vm.games = [
        {
          image: '/images/banner-tuquintico.gif',
          link: 'quintico',
          name: 'Tu Quintico',
          show: false
        },{
          image: '/images/banner-zoologico-v2.gif',
          link: 'zoologico',
          name: 'Zoologico de la suerte',
          show: false
        },{
          image: '/images/ligadito-gif.gif',
          link: 'ligadito',
          name: 'Ligadito',
          show: false
        },{
          image: '/images/banner-rifcar.gif',
          link: 'rifcar',
          name: 'Rifcar',
          show: false
        },
      ];
      vm.game = vm.games[_.random(0,4)];
    }

  }
})();
