(function () {
  'use strict';
  angular
    .module('app')
    .directive('checkAsideMenu', checkAsideMenu);

  checkAsideMenu.$inject = [];

  function checkAsideMenu() {
    return {
      link: link
    };

    function link(scope, el, attrs, ctrl) {
      $(el).click(function(){
        if($('body').hasClass('sidebar-open')){
          $('body').removeClass('sidebar-open');
        }
      })
    }
  }
})();
