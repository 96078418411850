(function() {
  'use strict';
  angular
    .module('app')
    .factory('SingUpService', SingUpService);

  SingUpService.$inject = ['$http', 'api'];

  function SingUpService($http, api) {

    return {
      confirmCustomer: confirmCustomer,
      // preRegisterCustomer: preRegisterCustomer,
      registerCustomer:registerCustomer,
      registerAgent: registerAgent
    };

    function confirmCustomer(token) {
      return $http.get(api.getApiSPJ('register/'+token))
      .then(function(res){
        return res.data;
      })
    }

    // function preRegisterCustomer(customer) {
    //   return $http.post(api.getApiSPJ('pre_register'), customer)
    //   .then(function(res){
    //     return res.data;
    //   })
    // }

    function registerCustomer(data) {
      return $http.post(api.getApiSPJ('register'), data)
      .then(function(res){
        return res.data;
      })
    }
    function registerAgent(data) {
      return $http.post(api.getApiSPJ('register/create_agent'), data)
      .then(function(res){
        return res.data;
      })
    }



  }
})();
