(function(){
  'use strict';

  angular
  .module('app')
  .filter('prettyTipo', prettyTipo);

  function prettyTipo(){

    return function(tipo){
      return {
        '1':'Agente',
        '2':'SubAgente',
        '3':'Agencia',
        '4':'Jugador',
        '5':'Punto'
      }[tipo];
    }

  }
})();
