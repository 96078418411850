(function () {
  'use strict';
  angular
  .module('app')
  .component('lobbyComponent', {
    templateUrl: 'app/components/lobby/lobby.html',
    controller: MainComponent,
    bindings: {
      productsAvailables: '<'
    }
  });

  MainComponent.$inject = ['$http', 'url', 'user','toastr', '$state','$rootScope','ActionCableChannel','$window'];

  function MainComponent($http, $url, $user, toastr, $state, $rootScope, ActionCableChannel, $window) {
    var vm = this;
    vm.currentUser = $user.getCurrentUser();
    vm.$onInit = function(){
      if ($window.localStorage.hasOwnProperty('cintillo')){
        vm.cintillo = JSON.parse($window.localStorage.getItem('cintillo'));
        $rootScope.$emit('updateCintillo', vm.cintillo);
      }
    }

    vm.taquillaConstruccion = function(){
      $window.swal('Alto','Juego en Construccion', 'warning')
    }
    
    $rootScope.$on('updatedUser',function(event,data){
      vm.currentUser = data;
    });

    $rootScope.$on('updateCajeroUser', function(evt, data){
      delete vm.currentUser.cajero
      vm.currentUser.cajero = data;
      $user.setCurrentUser(vm.currentUser);
    })

    $rootScope.$emit('updateNameState', {state: $state.current.name});

    $rootScope.$on('updateCintillo', function(evt, data){
      vm.cintillo = data;
    })
  }

})();
