(function(){
  'use strict';

  angular
  .module('app')
  .filter('prettyName', prettyName);

  function prettyName(){

    return function(combination){
      return {
        'loteries':'Triples de Loteria',
        'jungla':'Jungla Millonaria',
        'quintico':'Tu Quintico',
        'rifcar':'Rifcar',
        'granjita':'La Granjita',
        'lottoActivo': 'Lotto Activo',
        'laPolla': 'La Polla',
        'winners': 'Tabla de ganadores la Polla'
      }[combination];
    }

  }
})();
