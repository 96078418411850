(function () {
  'use strict';
  angular
  .module('app')
  .component('message', {
    templateUrl: 'app/components/messages/messages.html',
    controller: MessageController
  });

  MessageController.$inject = ['user','toastr', '$uibModal','$rootScope','miscelaneos'];

  function MessageController($user, toastr, $uibModal, $rootScope, $miscelaneos) {
    var vm = this;
    vm.send = false;

    vm.$onInit = function() {
      vm.currentUser = $user.getCurrentUser();
      $miscelaneos.getNotifications()
      .then(function(res){
        vm.notifications = _.map(res, function(data){
          data.checked = false;
          return data;
        });
      })
    }

    vm.toggleView = function(data){
      $miscelaneos.toggleViewNotification(data)
      .then(function(res){
        data.vista = res.notification.vista;
        toastr.info(res.message);
        $miscelaneos.getNewNotifications()
        .then(function(res){
          $rootScope.$broadcast('updateCountNotification',res)
        })
      }, function(err){
        toastr.warning(err.data.message);
      })
    }

    vm.viewMessage = function(notification){

      var modal = $uibModal.open({
        animation: true,
        windowClass: 'modalMessage',
        component: 'viewMessage',
        size: 'md',
        resolve: {
          notification: function() {
            return notification;
          }
        }
      });

      modal.result.then(function(res){
        $miscelaneos.getNewNotifications()
        .then(function(res){
          $rootScope.$broadcast('updateCountNotification',res)
        })
      }, function(err){
        $miscelaneos.getNewNotifications()
        .then(function(res){
          $rootScope.$broadcast('updateCountNotification',res)
        })
      })

      notification.vista = true;
    }

    vm.toggleViewNotifications = function(){
      var data = _.reduce(vm.notifications, function(memo, data){
        if(data.checked){
          memo.push(data.id)
        }
        return memo;
      },[])
      $miscelaneos.toggleViewNotification({id: data})
      .then(function(res){
        _.forEach(vm.notifications, function(data){
          if(data.checked){
            data.vista = true;
            data.checked = false;
          }
        })
        toastr.info(res.message);
        $miscelaneos.getNewNotifications()
        .then(function(res){
          $rootScope.$broadcast('updateCountNotification',res)
        })
      }, function(err){
        toastr.warning(err.data.message);
      })
    }



  }

})();
