angular
.module('app')
.component('webLayout', {
  templateUrl: 'app/components/layouts/web-layout.html',
  controller: WebLayoutController
});

WebLayoutController.$inject = ['$window', 'ActionCableChannel', '$timeout','user','$rootScope','miscelaneos'];

function WebLayoutController($window, ActionCableChannel, $timeout, $user, $rootScope, $miscelaneos) {
  var vm = this;
  vm.reloadCache = reloadCache;
  vm.$onInit = function(){
    if($user.getCurrentUser()){
      vm.user = $user.getCurrentUser();
    }
  }

  if($user.getCurrentUser()){
    var channel = new ActionCableChannel("SystemNotificationChannel", {});
    var callback = function(notification){
      vm.notification = notification;

      switch (notification.type) {
        case 0:
          var statusBar = angular.element(document.querySelector('#statusBar'));
          statusBar.addClass('update');
          showNotification(notification);
        break;
        case 1:
          var statusBar = angular.element(document.querySelector('#statusBar'));
          statusBar.addClass('error-conection');
          showNotification(notification);
        break;
        case 2:
          if(vm.user.id == notification.send_to){
            var statusBar = angular.element(document.querySelector('#statusBar'));
            statusBar.addClass('notification-info');
            showNotification(notification);
          }
        break;
        case 3:
          if(vm.user.id == notification.send_to){
            var statusBar = angular.element(document.querySelector('#statusBarMini'));
            statusBar.addClass('notification-info');
            showNotification(notification);
          }
        break;
        case 4:
        if(vm.user.id == notification.send_to){
          var statusBar = angular.element(document.querySelector('#statusBarMini'));
          statusBar.addClass('notification-info');
          showNotification(notification);
          $rootScope.$broadcast('updateCajeroUser', notification.cajero);
          $rootScope.$broadcast('upateLastTransaction');
        }
        break;
        case 5:
          if(vm.user.id == notification.send_to){
            var statusBar = angular.element(document.querySelector('#statusBarMini'));
            statusBar.addClass('notification-info');
            showNotification(notification);
            $rootScope.$broadcast('updateAmountEffecty', notification.total_effecty);
          }
        break;
      default:

      }
    };
    channel.subscribe(callback)
    .then(function(){
      vm.sendToMyChannel = function(message){
        channel.send(message);
      };
    });
  }

  function reloadCache(){
    var newVersion = {version: vm.notification.version}
    var currentVersion = angular.fromJson($window.localStorage.system_version)
    if(newVersion != currentVersion){
      $window.localStorage.removeItem('system_version');
      $timeout(function(){
        $window.localStorage.setItem('system_version', angular.toJson(newVersion))
        $timeout(function(){
          $window.location.reload(true);
        },1)
      },0)
    }
  }

  vm.closeNotification = function(){
    if(vm.notification.type == 3 || vm.notification.type == 4){
      vm.notification = undefined;
      vm.showMessageNotificationMini = false;
      var element = angular.element(document.querySelector('html'));
      element.removeClass('hasNotificationMini');
    }else{
      vm.notification = undefined;
      vm.showMessageNotification = false;
      var element = angular.element(document.querySelector('html'));
      element.removeClass('hasNotification');
    }
  }

  function showNotification(notification){
    $timeout(function () {
      var element = angular.element(document.querySelector('html'));
      if(notification.type == 3 || notification.type == 4 || notification.type == 5){
        element.addClass('hasNotificationMini');
      }else{
        element.addClass('hasNotification');
      }
    },1);

    if(notification.type == 3 || notification.type == 4 || notification.type == 5){
      $timeout(function(){
        vm.showMessageNotificationMini = true;
        $timeout(function(){
          // vm.closeNotification()
        },5000);
      },200)
    }else{
      $timeout(function(){
        vm.showMessageNotification = true;
        $timeout(function(){
          // vm.closeNotification()
        },5000);
      },700)
    }
  }

  $rootScope.$on('offline', function(event, data){
    vm.notification = {type: 1}
    var statusBar = angular.element(document.querySelector('#statusBar'));
    statusBar.addClass('error-conection');
    showNotification({type: 1});
    vm.showMessageNotification = true;
  });
  $rootScope.$on('online', function(event, data){
    vm.closeNotification()
  });

}
