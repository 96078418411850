(function () {
  'use strict';
  angular
  .module('app')
  .component('pageMarketer', {
    templateUrl: 'app/components/page-markerter/page-marketer.html',
    controller: PageMarketerController,
  });

  PageMarketerController.$inject = ['$window', 'user','toastr','agentsPanelService','$rootScope','$stateParams','$state','DOMAIN_URL'];

  function PageMarketerController($window, $user, toastr, $agentsPanelService, $rootScope, $stateParams, $state, DOMAIN_URL) {
    var vm = this;
    vm.print = print;

    vm.$onInit = function(){
      vm.currentUser = $user.getCurrentUser();
      vm.currentUser.promotional_code_url = DOMAIN_URL + '/registro?tipo_de_registro=jugadores&promotional_code='+vm.currentUser.promotional_code;
    }



    function print(){
      $window.print();
    }



  }

})();
