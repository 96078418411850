(function(){
  'use strict';

  angular
  .module('app')
  .filter('getPrettyDrawLiga', getPrettyDrawLiga);

  function getPrettyDrawLiga(){

    return function(status){
      return {
        "Ligadito-9": 'El Ligadito 9AM',
        "Ligadito10": 'El Ligadito 10AM',
        "Ligadito11": 'El Ligadito 11AM',
        "Ligadito12": 'El Ligadito 12M',
        "Ligadito01": 'El Ligadito 1PM',
        "Ligadito02": 'El Ligadito 2PM',
        "Ligadito04": 'El Ligadito 4PM',
        "Ligadito05": 'El Ligadito 5PM',
        "Ligadito06": 'El Ligadito 6PM',
        "Ligadito07": 'El Ligadito 7PM'
		  }[status];
    }

  }
})();
