(function () {
  'use strict';
  angular
  .module('app')
  .component('myBonus', {
    templateUrl: 'app/components/bonus/bonus.html',
    controller: BonusController,
  });

  BonusController.$inject = ['$window', 'user','agentsPanelService','miscelaneos'];

  function BonusController($window, $user, $agentsPanelService,$miscelaneos) {
    var vm = this;
    vm.currentUser = $user.getCurrentUser();

    vm.$onInit = function(){
      vm.imagenes = $miscelaneos.getImagesToBonus();

      $agentsPanelService.getMyPercentsBonus()
      .then(function(res){
        vm.percentsBonus = _.map(res, function(data){
          var image = _.findWhere(vm.imagenes, {loteria_id: data.loteria_id});
          data.comision = parseInt(data.comision);
          data.image = image ? image.image : null;
          return data;
        });
      })
    }

  }

})();
