(function(){
  'use strict';

  angular
  .module('app')
  .filter('getPrettyDrawJungla', getPrettyDrawJungla);

  function getPrettyDrawJungla(){

    return function(status){
      return {
        "Jungla5pm": 'Jungla Millonaria 9AM',
        "Jungla10am": 'Jungla Millonaria 10AM',
        "Jungla1pm": 'Jungla Millonaria 11AM',
        "Jungla11am": 'Jungla Millonaria 12M',
        "Jungla12m": 'Jungla Millonaria 1PM',
        "Jungla2pm": 'Jungla Millonaria 2PM',
        "Jungla3pm": 'Jungla Millonaria 4PM',
        "Jungla4pm": 'Jungla Millonaria 5PM',
        "Jungla6pm": 'Jungla Millonaria 6PM',
        "Jungla7pm": 'Jungla Millonaria 7PM'
		  }[status];
    }

  }
})();
