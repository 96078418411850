(function () {
  'use strict';
  angular
  .module('app')
  .component('agentsPanel', {
    templateUrl: 'app/components/agents-panel/agents-panel.html',
    controller: AgentsPanelController,
    bindings: {
      cajeroWeb: '<'
    }
  });

  AgentsPanelController.$inject = ['user','toastr', '$uibModal','$rootScope','agentsPanelService', '$state'];

  function AgentsPanelController($user, toastr, $uibModal, $rootScope, $agentsPanelService, $state) {
    var vm = this;
    vm.currentUser = $user.getCurrentUser();
    vm.loading = false;

    vm.$onInit = function() {
      vm.loading = true;
    }

  }

})();
