angular
  .module('app')
  .component('hipismo', {
    templateUrl: 'app/components/hipismo/hipismo.html',
    controller: HipismoController,
    bindings: {
      settings: '<'
    }
  });

  HipismoController.$inject = ['user','ActionCableChannel','cashierService','$uibModal','$sce', '$timeout'];

function HipismoController($user, ActionCableChannel, cashierService, $uibModal, $sce, $timeout) {
  var vm = this;
  
  vm.$onInit = function(){
    vm.currentUser = $user.getCurrentUser();

    var channel = new ActionCableChannel("PrinterChannel", {});
    var callback = function(notification){
      vm.notification = notification;
      if(vm.notification.estructura_id == vm.currentUser.id || vm.notification.estructura_id == vm.currentUser.padre_id){
        getNotification(vm.notification)
      }
    };

    channel.subscribe(callback)
    .then(function(){
      console.log('Conectado al Canal')
      vm.sendToMyChannel = function(message){
        channel.send(message);
      };
    });
  }

  function getNotification(data){
    cashierService.getTransaction(data.operation_id)
    .then(function(res){
      vm.ticket = $sce.trustAsHtml(angular.copy(res.detalle.replace(/\\n/g, '<br>')));
      vm.ticket_print = res.detalle;
      $timeout(function(){
        showTicket(vm.ticket, vm.ticket_print)
      },0)
    })
  }

  function showTicket(ticket, ticket_print){
    var modal = $uibModal.open({
      animation: true,
      component: 'gModalTicket',
      backdrop: 'static',
      windowClass: 'modalTicket',
      size: 'xs',
      resolve: {
        ticket: function() {
          return ticket;
        },
        ticket_print: function(){
          return ticket_print;
        }
      },
    });
  }



  
    

}
