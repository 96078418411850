angular
  .module('app')
  .filter('imageUrlRifas', imageUrlRifas);

function imageUrlRifas(OPERADORA_URL) {

  return function (relativePath, defaultPath) {

    // transform the item and return it
    if (relativePath === null) {// cargando o image-null
      return defaultPath || 'images/loader.gif';
    }
    if (relativePath[0] === 'h') {// si comienza por http es absoluta
      return relativePath || (OPERADORA_URL + relativePath);
    }
    return OPERADORA_URL + relativePath;
  };
}
