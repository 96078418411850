angular
  .module('app')
  .component('home', {
    templateUrl: 'app/components/home/home.html',
    controller: HomeController
  });

  HomeController.$inject = ['moment','$timeout', '$uibModal','miscelaneos'];

function HomeController(moment, $timeout, $uibModal, $miscelaneos) {
  var vm = this;
  vm.active = 0;
  vm.date = moment();
  vm.resultsZoo = [];
  vm.loading1 = true;
  vm.loading2 = false;
  vm.loading3 = false;
  vm.loading4 = true;
  vm.myInterval = 4000;
  vm.resultQuintico = [];
  vm.noWrapSlides = false;
  vm.humanizeNumbers = ['Cero','Uno','Dos','Tres','Cuatro','Cinco','Seis','Siete','Ocho','Nueve'];
  vm.slides = [
    {
      image: '/images/home/banner_parley.jpg',
      link: 'home'
    },
    {
      image: '/images/home/banner-stadium.jpeg',
      link: 'home'
    },{
      image: '/images/home/banner_hipico.jpg',
      link: 'home'
    }
  ];

  vm.$onInit = function(){

    $miscelaneos.getCurrentPromo()
    .then(function(res){
      if(res){
        var modal = $uibModal.open({
          animation: true,
          component: 'modalPromo',
          windowClass: 'modalPopup',
          size: 'md',
          resolve: {
            promo: function(){
              return res;
            }
          }
        });
      }
    })
  }

}
