(function () {
  'use strict';
  angular
  .module('app')
  .component('auth', {
    templateUrl: 'app/components/auth/auth.html',
    controller: AuthController
  });

  AuthController.$inject = ['authService', 'user', '$window','$rootScope','$state','availableProducts'];

  function AuthController($authService, $user, $window, $rootScope, $state,availableProducts) {
    var vm = this;
    vm.send = false;
    vm.doLogin = doLogin;
    vm.user = {email: '', password: ''};

    function doLogin() {

      vm.send = true;
      $authService.doLogin(vm.user)
      .then(function (res) {
        console.log(res)
        vm.send = false;
        vm.user = res.estructura;
        availableProducts.setCurrentProducts(res.products);
        $user.setCurrentUser(vm.user);
        $rootScope.$broadcast('updatedUser', vm.user);
        if(vm.user.tipo_estructura_id == 2){
          $window.location = '/panel-de-agentes/reporte';
        }else{
          $window.location = '/lobby';
        }
      }, function (err) {
        vm.send = false;
        $window.swal('Error!',err.data.message,'warning');
      });
    }
  }

})();
