(function (){
  'use strict'
  angular
  .module('app')
  .factory('miscelaneos', miscelaneos);

  miscelaneos.$inject = ['api','$http','user']

  function miscelaneos(api, $http, $user){
    return {
      getCurrency: getCurrency,
      getBanksOperadora: getBanksOperadora,
      addBankStructure: addBankStructure,
      updateBankStructure: updateBankStructure,
      getBanksStructure: getBanksStructure,
      getAndUncompressDraws: getAndUncompressDraws,
      getSigns: getSigns,
      getSystemVersions: getSystemVersions,
      getBancos: getBancos,
      updateProfile: updateProfile,
      getNotifications: getNotifications,
      getNewNotifications: getNewNotifications,
      toggleViewNotification: toggleViewNotification,
      getDefaultSolicitudOperacion: getDefaultSolicitudOperacion,
      getSettingCajeroWeb: getSettingCajeroWeb,
      getImagesToBonus: getImagesToBonus,
      getNumbersPermuted: getNumbersPermuted,
      getSerieNumbers: getSerieNumbers,
      getCorridaNumbers: getCorridaNumbers,
      unsubscribe: unsubscribe,
      getCurrentPromo: getCurrentPromo,
      getProductIdByGame: getProductIdByGame,
      findLasStuds: findLasStuds,
      getCurrentCintillo: getCurrentCintillo
    }

    function toggleViewNotification(data){
      return $http.post(api.getApiSPJ('notifications/toggle_view_notification'), data)
      .then(function(res){
        return res.data;
      })
    }

    function getNewNotifications(){
      return $http.get(api.getApiSPJ('notifications/new_notifications'))
      .then(function(res){
        return res.data;
      })
    }

    function getNotifications(){
      return $http.get(api.getApiSPJ('notifications'))
      .then(function(res){
        return res.data;
      })
    }

    function updateProfile(data){
      return $http.post(api.getApiSPJ('estructura/update_profile'), data)
      .then(function(res){
        return res.data;
      })
    }


    function getBanksOperadora(){
      return $http.get(api.getApiSPJ('bancos_operadora'))
      .then(function(res){
        return res.data;
      })
    }

    function getBanksStructure(){
      return $http.get(api.getApiSPJ('bancos_estructura'))
      .then(function(res){
        return res.data;
      })
    }

    function addBankStructure(data){
      return $http.post(api.getApiSPJ('bancos_estructura'), data)
      .then(function(res){
        return res.data;
      })
    }
    function updateBankStructure(id, data){
      return $http.put(api.getApiSPJ('bancos_estructura/'+id), data)
      .then(function(res){
        return res.data;
      })
    }

    function getCurrency(){
      return {symbol: 'Bs.S ', decimal: 2, separatorMiles: '.', separatorDecimal: ','}
    }

    function getSystemVersions(){
      return $http.get(api.getApiSPJ('miscelaneos/get_system_versions'))
      .then(function(res){
        return res.data;
      })
    }
    function getCurrentPromo(){
      return $http.get(api.getApiSPJ('miscelaneos/get_current_promo'))
      .then(function(res){
        return res.data;
      })
    }

    function getBancos(){
      return $http.get(api.getApiSPJ('miscelaneos/bancos'))
      .then(function(res){
        return res.data;
      })
    }

    function getDefaultSolicitudOperacion(){
      return $http.get(api.getApiSPJ('miscelaneos/default_solicitud_operacion'))
      .then(function(res){
        return res.data;
      })
    }

    function getSettingCajeroWeb(){
      return $http.get(api.getApiSPJ('miscelaneos/get_setting_cajero_web '))
      .then(function(res){
        return res.data;
      })
    }

    function getAndUncompressDraws(){
      var b64Data     = $user.getCurrentUser().super_juegos.lote;
      var strData     = atob(b64Data);
      var charData    = strData.split('').map(function(x){return x.charCodeAt(0);});
      var binData     = new Uint8Array(charData);
      var data        = pako.inflate(binData);
      var strData     = String.fromCharCode.apply(null, new Uint16Array(data));
      return JSON.parse(strData);
    }

    function getNumbersPermuted(data){
      return $http.post(api.getApiSPJ('miscelaneos/get_numbers_permuted'), data)
      .then(function(res){
        return res.data;
      })
    }

    function getSerieNumbers(data){
      return $http.post(api.getApiSPJ('miscelaneos/get_serie_numbers'), data)
      .then(function(res){
        return res.data;
      })
    }
    function getCorridaNumbers(data){
      return $http.post(api.getApiSPJ('miscelaneos/get_corrida_numbers'), data)
      .then(function(res){
        return res.data;
      })
    }

    function unsubscribe(id){
      return $http.get(api.getApiSPJ('estructura/unsubscribe?id='+id))
      .then(function(res){
        return res.data;
      })
    }

    function findLasStuds(id){
      return $http.post(api.getUrlVentaCentinela('consultas/find_lasts_studs'), {cod: id})
      .then(function(res){
        return res.data;
      })
    }

    function getCurrentCintillo(){
      return $http.get(api.getApiSPJ('miscelaneos/current_cintillo'))
      .then(function(res){
        return res.data;
      })
    }



    function getSigns() {
            return [
        {
          name: "Aries",
          imagen: "/images/signos/aries.png",
          new_image: "/images/signos/aries-gray.png",
          checked: false,
          available: true,
          code: 1
        },
        {
          name: "Tauro",
          imagen: "/images/signos/tauro.png",
          new_image: "/images/signos/tauro-gray.png",
          checked: false,
          available: true,
          code: 2
        },
        {
          name: "Geminis",
          imagen: "/images/signos/geminis.png",
          new_image: "/images/signos/geminis-gray.png",
          checked: false,
          available: true,
          code: 3
        },
        {
          name: "Cancer",
          imagen: "/images/signos/cancer.png",
          new_image: "/images/signos/cancer-gray.png",
          checked: false,
          available: true,
          code: 4
        },
        {
          name: "Leo",
          imagen: "/images/signos/leo.png",
          new_image: "/images/signos/leo-gray.png",
          checked: false,
          available: true,
          code: 5
        },
        {
          name: "Virgo",
          imagen: "/images/signos/virgo.png",
          new_image: "/images/signos/virgo-gray.png",
          checked: false,
          available: true,
          code: 6
        },
        {
          name: "Libra",
          imagen: "/images/signos/libra.png",
          new_image: "/images/signos/libra-gray.png",
          checked: false,
          available: true,
          code: 7
        },
        {
          name: "Escorpio",
          imagen: "/images/signos/escorpio.png",
          new_image: "/images/signos/escorpio-gray.png",
          checked: false,
          available: true,
          code: 8
        },
        {
          name: "Sagitario",
          imagen: "/images/signos/sagitario.png",
          new_image: "/images/signos/sagitario-gray.png",
          checked: false,
          available: true,
          code: 9
        },
        {
          name: "Capricornio",
          imagen: "/images/signos/capricornio.png",
          new_image: "/images/signos/capricornio-gray.png",
          checked: false,
          available: true,
          code: 10
        },
        {
          name: "Acuario",
          imagen: "/images/signos/acuario.png",
          new_image: "/images/signos/acuario-gray.png",
          checked: false,
          available: true,
          code: 11
        },
        {
          name: "Piscis",
          imagen: "/images/signos/piscis.png",
          new_image: "/images/signos/piscis-gray.png",
          checked: false,
          available: true,
          code: 12
        }
      ]
    }

    function getImagesToBonus(){
      return [
        {
          loteria_id: 4,
          image: '/images/games/tq-lobby-1598898341.png'
        },{
          loteria_id: 3,
          image: '/images/games/rc-lobby-1598898341.png'
        },{
          loteria_id: 5,
          image: '/images/games/triple-zulia-1598898341.png'
        },{
          loteria_id: 9,
          image: '/images/games/triple-zamorano-1598898341.png'
        },{
          loteria_id: 15,
          image: '/images/games/jungla-lobby-03102059.png'
        },{
          loteria_id: 6,
          image: '/images/games/triple-tachira.png'
        },{
          loteria_id: 13,
          image: '/images/games/triple-caliente-1598898341.png'
        },{
          loteria_id: 17,
          image: '/images/games/la-granjita.png'
        },{
          loteria_id: 8,
          image: '/images/games/triple-caracas.png'
        },{
          loteria_id: 7,
          image: '/images/games/chance-en-linea.png'
        },{
          loteria_id: 18,
          image: '/images/games/lotto-activo-1.png'
        },{
          loteria_id: 19,
          image: '/images/games/lotto-activo-1.png'
        }
      ]
    }
  }

  function getProductIdByGame(game){
    return {
      'TuQuintico': 1,
      'Jungla': 2,
      'Rifcar': 3,
      'Triples': 5,
      'LaGranjita': 6,
      'LottoActivo': 8,
      'LaPolla': 9,
    }[game];

  }




})();
