(function () {
  angular
  .module('app')
  .factory('authService', authService);
})();

authService.$inject = ['$http', 'url', 'user', '$window', '$state', 'api'];

function authService($http, url, $user, $window, $state, $api) {

  function doLogin(user) {
    return $http.post($api.getApiSPJ('login'), user)
    .then(function(res){
      return res.data;
    })
  }

  function logout() {
    var user = $user.getCurrentUser();
    $user.setCurrentUser(null);
    $window.localStorage.clear();
  }

  function getCodeToResetPassword(data){
    return $http.post($api.getApiSPJ('login/reset_password'), data)
    .then(function(res){
      return res.data;
    })
  }
  function verifyCode(data){
    return $http.post($api.getApiSPJ('login/verify_code'), data)
    .then(function(res){
      return res.data;
    })
  }
  function updatePassword(data){
    return $http.post($api.getApiSPJ('login/update_password'), data)
    .then(function(res){
      return res.data;
    })
  }

  function goToPS(){
    return $http.post($api.getApiSPJ('login/login_to_pv'))
    .then(function(res){
      return res.data;
    })
  }

  function backToCDA(){
    return $http.post($api.getApiSPJ('login/login_to_cda'))
    .then(function(res){
      return res.data;
    })
  }


  return {
    doLogin: doLogin,
    logout: logout,
    getCodeToResetPassword: getCodeToResetPassword,
    updatePassword: updatePassword,
    verifyCode: verifyCode,
    goToPS: goToPS,
    backToCDA: backToCDA
  }
}
