(function () {
  'use strict';
  angular
  .module('app')
  .component('subAgents', {
    template: '<div ng-include="$ctrl.urlTemplate"></div>',
    controller: SubAgentsController,
  });

  SubAgentsController.$inject = ['$window', 'user','toastr','agentsPanelService','$rootScope','$stateParams','$state'];

  function SubAgentsController($window, $user, toastr, $agentsPanelService, $rootScope, $stateParams, $state) {
    var vm = this;
    vm.currentUser = $user.getCurrentUser();
    vm.saveSubAgents = saveSubAgents;
    vm.viewPromotionalCode = false;
    vm.showPrintCode = showPrintCode;
    vm.print = print;

    vm.$onInit = function(){
      if($stateParams.action == 'crear-sub-agentes'){
        vm.urlTemplate = 'app/components/sub-agents/crear-sub-agents.html';
      }else{
        vm.urlTemplate = 'app/components/sub-agents/list-sub-agents.html';
        $agentsPanelService.getMySubAgents()
        .then(function(res){
          vm.sub_agentes = res;
          vm.loading = false;
        })
      }

      $agentsPanelService.getMyPercentsBonus()
      .then(function(res){
        vm.percentsBonus = _.map(res, function(data){
          data.referencial = parseInt(data.comision);
          return data;
        })
      })

    }

    function saveSubAgents(){
      _.forEach(vm.percentsBonus, function(data){
        if(parseInt(data.referencial) == parseInt(data.comision)){
          toastr.error('El bono para '+data.producto+' no puede ser igual al monto'+data.comision);
        }
      })
      var configuracion_estructura;
      configuracion_estructura = _.reduce(vm.percentsBonus, function(memo, data){
        memo.push({
          comision: parseInt(data.referencial),
          loteria_id: data.loteria_id,
          participacion: parseInt(data.participacion)
        })
        return memo;
      },[])
      var data = {
        nombre: 'cda '+vm.sub_agent.nombre,
        representante: vm.sub_agent.representante,
        telefono: vm.sub_agent.telefono,
        direccion:vm.sub_agent.direccion,
        correo: vm.sub_agent.username,
        rif: vm.sub_agent.rif,
        activo: true,
        username: vm.sub_agent.username,
        password: vm.sub_agent.password,
        password_confirmation:vm.sub_agent.password_confirmation,
        configuracion_estructura: configuracion_estructura
      }

      $agentsPanelService.createSubAgents(data)
      .then(function(res){
        vm.sub_agent = undefined;
        toastr.success(res.message);
        $state.go('agentspanel');
      })
    }

    function showPrintCode(data){
      vm.viewPromotionalCode = true;
      vm.currentSubAgents = data;
      vm.currentSubAgents.promotional_code_url = 'http://www.realparley.com/register/'+data.promotional_code
    }

    function print(){
      $window.print();
    }



  }

})();
