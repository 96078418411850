(function () {
  'use strict';
  angular
  .module('app')
  .factory('availableProducts', availableProducts);

  availableProducts.$inject = ['$window', '$rootScope'];

})();

function availableProducts($window, $rootScope) {
  var products;

  if ($window.localStorage.hasOwnProperty('availableProducts')) {
    products = angular.fromJson($window.localStorage.getItem('availableProducts'));
  }
  function getCurrentProducts() {
    return products;
  }
  function setCurrentProducts(products) {
    $window.localStorage.setItem('availableProducts', angular.toJson(products));
  }
  return {
    getCurrentProducts: getCurrentProducts,
    setCurrentProducts: setCurrentProducts
  };
}
