(function(){
  'use strict';
  angular
  .module('app')
  .directive('copyToClipboard', copyToClipboard);

  copyToClipboard.$inject = ['DOMAIN_URL','toastr'];

  function copyToClipboard(DOMAIN_URL, toastr){
    return {
      link: link
    };


    function link(scope, element, attrs) {
      $(element).click(function(){
        if (attrs.copyToClipboard) {
          var text = attrs.copyToClipboard;
          var $temp_input = $("<input>");
          $("body").append($temp_input);
          $temp_input.val(text).select();
          document.execCommand("copy");
          $temp_input.remove();
          toastr.info('Link copiado')
        }
      })
    }
  }

})();
