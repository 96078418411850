(function () {
  'use strict';
  angular
  .module('app')
  .factory('user', user);

  user.$inject = ['$window', '$rootScope'];

})();

function user($window, $rootScope) {
  var currentUser;

  if ($window.localStorage.hasOwnProperty('user')) {
    currentUser = angular.fromJson($window.localStorage.getItem('user'));
  }
  function getCurrentUser() {
    return currentUser;
  }
  function setCurrentUser(currentUser) {
    $window.localStorage.setItem('user', angular.toJson(currentUser));
  }
  return {
    getCurrentUser: getCurrentUser,
    setCurrentUser: setCurrentUser
  };
}
