(function() {
  'use strict';
  angular
    .module('app')
    .factory('rifcarServices', rifcarServices);

  rifcarServices.$inject = ['$http', 'api'];

  function rifcarServices($http, api) {
    return {
      buyCombinations: buyCombinations,
      getRandomCombination: getRandomCombination,
      confirmSale: confirmSale,
      revertSale: revertSale,
      getRandCombinations: getRandCombinations
    };

    function buyCombinations(data) {
      return $http.post(api.getUrlVentaCentinela('rifcar/nueva_venta_rifcar_cda'),data)
      .then(function(res){
        return res.data;
      })
    }

    function confirmSale(data){
      return $http.post(api.getUrlVentaCentinela('rifcar/confirmar_venta'), data)
      .then(function(res){
        return res.data;
      })
    }
    function revertSale(data){
      return $http.post(api.getUrlVentaCentinela('rifcar/reversar_venta'), data)
      .then(function(res){
        return res.data;
      })
    }

    function getRandomCombination(draw_id){
      return $http.post(api.getUrlVentaCentinela('tickets/random'), draw_id)
      .then(function(res){
        return res.data
      })
    }

    function getRandCombinations(data){
      return $http.post(api.getCentinelaApi('rifcar/venta_aleatoria'), data)
      .then(function(res){
        return res.data
      })
    }
  }
})();
